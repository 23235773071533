import React from "react";
import { DialogActions, Button, Box } from "@material-ui/core";
import { ActionButtonsProps } from "./types";

/**
 * アクションボタンコンポーネント
 * ダイアログのアクション（保存、削除、チェックインなど）の表示と実行を担当
 */
const ActionButtons: React.FC<ActionButtonsProps> = ({
  onSave,
  onClose,
  onDelete,
  onCheckIn,
  isEditing = false,
  isCreateUser = false,
  isCheckedIn = false,
  isLogin = false,
  isLoading = false,
  hasLocationData = false,
  generateGoogleMapLink,
  hasChanges = true,
}) => {
  // 編集または新規作成時
  return (
    <DialogActions
      style={{
        padding: "8px 24px",
        justifyContent: "space-between", // 両端揃えのレイアウト
        flexWrap: "wrap",
      }}
    >
      {/* 既存マーカー閲覧時のチェックインボタン（編集モードでない、かつ位置情報がある場合） */}
      {!isEditing &&
        isLogin &&
        onCheckIn &&
        !isCheckedIn &&
        hasLocationData && (
          <Button
            color="primary"
            variant="contained"
            onClick={onCheckIn}
            disabled={isLoading}
            style={{ marginBottom: "8px", flex: "1 0 100%" }}
          >
            チェックイン
          </Button>
        )}

      {/* 既存マーカー閲覧時のGoogle Mapsボタン（位置情報がある場合） */}
      {!isEditing && hasLocationData && generateGoogleMapLink && (
        <Button
          color="default"
          variant="outlined"
          href={generateGoogleMapLink()}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginBottom: "8px", flex: "1 0 100%" }}
        >
          Google Mapsで開く
        </Button>
      )}

      {/* 既存マーカー閲覧時の閉じるボタン - 右端に配置 */}
      {!isEditing && (
        <Box
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={onClose} color="primary">
            閉じる
          </Button>
        </Box>
      )}

      {/* 編集/新規作成時のボタン配置 */}
      {isEditing && (
        <>
          {/* 左側：削除ボタン */}
          <Box>
            {onDelete && isCreateUser && hasLocationData && (
              <Button
                onClick={onDelete}
                color="secondary"
                variant="outlined"
                disabled={isLoading}
              >
                削除
              </Button>
            )}
          </Box>

          {/* 右側：キャンセルと保存ボタン */}
          <Box>
            <Button
              onClick={onClose}
              color="secondary"
              style={{ marginRight: "8px" }}
            >
              キャンセル
            </Button>

            <Button
              onClick={onSave}
              color="primary"
              variant="contained"
              disabled={isLoading || !hasChanges}
            >
              {isLoading ? "保存中..." : "保存"}
            </Button>
          </Box>
        </>
      )}
    </DialogActions>
  );
};

export default ActionButtons;
