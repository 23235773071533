import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { FormFieldsProps } from "./types";

// テキストの色を黒くするためのスタイル
const useStyles = makeStyles({
  textField: {
    "& .MuiInputBase-input": {
      color: "black",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiInputBase-root.Mui-disabled .MuiInputBase-input": {
      color: "rgba(0, 0, 0, 0.8)",
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.8)",
    },
  },
});

/**
 * フォームフィールドコンポーネント
 * 入力フィールドの表示と入力値の管理を担当
 */
const FormFields: React.FC<FormFieldsProps> = ({
  name,
  title,
  explanation,
  setName,
  setTitle,
  setExplanation,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <div style={{ marginTop: "16px", marginBottom: "16px" }}>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="聖地名"
        type="text"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={disabled}
        style={{ marginBottom: "12px" }}
        className={classes.textField}
      />
      <TextField
        margin="dense"
        id="title"
        label="タイトル"
        type="text"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={disabled}
        style={{ marginBottom: "12px" }}
        className={classes.textField}
      />
      <TextField
        margin="dense"
        id="explanation"
        label="説明"
        type="text"
        fullWidth
        multiline
        minRows={3}
        maxRows={5}
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        disabled={disabled}
        className={classes.textField}
      />
    </div>
  );
};

export default FormFields;
