import React, { useEffect, useState, useRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { withStyles, Button, Snackbar, TextField } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import PhotoDropDialog from "../components/PhotoDropDialog";
import {
  listMaps,
  registMaps,
  setSearchPlace,
  setCurrentLocation,
  setStamp,
  setFilteringMaps,
  deleteMap,
  loginOrLogoutWithTwitter,
  getClusters,
  checkAuth,
  LOGIN_STATUS,
  checkInLocation,
} from "../../state/ducks/map/actions";
import SearchPlace from "../components/searchPlace";
import useSupercluster from "use-supercluster";
import { RootState } from "../../state/store";
import {
  MapData,
  Location,
  Cluster as ClusterType,
  ClusterProperties,
  SearchResultMarker as SearchResultMarkerType,
} from "../../types/map";
import LoginButton from "../components/map/LoginButton";
import { useAuth, AUTH_PROVIDERS } from "../../hooks/useAuth";

// アプリ初期表示時に画面に日本が収まるような位置
const defaultCenter: Location = {
  lat: 39.237363871491354,
  lng: 137.33612457223325,
};

const onStampStyle = {
  borderRadius: 50,
  border: "solid",
  borderColor: "hotpink",
};

// チェックイン済みスタイル
const checkedInStyle = {
  borderRadius: 50,
  border: "solid 2px",
  borderColor: "gold",
  boxShadow: "0 0 8px rgba(255, 215, 0, 0.6)",
};

interface PlaceProps {
  lat: number;
  lng: number;
  name: string;
  url: string;
  isStampRallyMode: boolean;
  isStamped: boolean;
  isCheckedIn: boolean;
  isCreateUser: boolean;
  onClickMarker: (e: React.MouseEvent) => void;
}

const Place: React.FC<PlaceProps> = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img
      src={props.url}
      width={40}
      height={40}
      style={
        props.isStampRallyMode && props.isStamped
          ? { ...onStampStyle }
          : props.isCheckedIn
          ? { ...checkedInStyle }
          : { borderRadius: 50 }
      }
      alt={props.name}
      onClick={props.onClickMarker}
    />
    <div
      style={{
        width: 76,
        maxWidth: 76,
        height: 15,
        fontSize: "100%",
        fontWeight: 700,
        textAlign: "center",
        padding: "1px 2px",
        borderTop: props.isCreateUser
          ? "4px solid #ff6161"
          : "4px solid #ffcfcf",
        background: "#f4f4f4",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
          position: "relative",
        }}
      >
        {props.name}
        {props.isCheckedIn && (
          <span
            style={{
              position: "absolute",
              right: "-2px",
              top: "-2px",
              fontSize: "10px",
            }}
          >
            🏆
          </span>
        )}
      </div>
    </div>
  </div>
);

interface MakerProps {
  lat?: number;
  lng?: number;
  url: string;
}

const Maker: React.FC<MakerProps> = (props) => (
  <img
    src={props.url}
    width={59}
    height={59}
    style={{ position: "relative", bottom: 25, left: 25 }}
    alt="marker"
  />
);

interface ClusterProps {
  lat: number;
  lng: number;
  cluster: {
    id: string;
    properties: {
      point_count: number;
    };
  };
}

const Cluster: React.FC<ClusterProps> = (props) => {
  const cnt =
    99 < props.cluster.properties.point_count
      ? "99+"
      : props.cluster.properties.point_count;
  return (
    <div
      style={{
        background: "violet",
        opacity: "0.6",
        minWidth: 40,
        minHeight: 40,
        display: "inline-flex",
        borderRadius: "100%",
        transform: "translate(-50%, -50%)",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ fontSize: 26, fontWeight: 700 }}>{cnt}</div>
    </div>
  );
};

interface SearchResultMarkerProps {
  lat: number;
  lng: number;
  name: string;
  url: string;
}

const SearchResultMarker: React.FC<SearchResultMarkerProps> = (props) => (
  <div style={{ position: "relative" }}>
    <img
      src={props.url}
      width={40}
      height={40}
      style={{
        position: "absolute",
        transform: "translate(-50%, -100%)",
        cursor: "pointer",
      }}
      alt={props.name || "検索結果"}
    />
    <div
      style={{
        position: "absolute",
        top: "-45px",
        left: "-40px",
        width: "80px",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        borderRadius: "4px",
        padding: "2px 5px",
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center",
        boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.name || "検索結果"}
    </div>
  </div>
);

// Redux stateをpropsにマッピング
const mapStateToProps = (state: RootState) => {
  // ログ出力を追加（デバッグ用）
  console.log("mapStateToProps - Redux State:", {
    loginStatus: state.mapState?.map?.loginStatus,
    loginUserInfo: state.mapState?.map?.loginUserInfo,
  });

  return {
    mapsData:
      (state.mapState && state.mapState.map && state.mapState.map.mapsData) ||
      [],
    clusters:
      (state.mapState && state.mapState.map && state.mapState.map.clusters) ||
      [],
    allMaps:
      (state.mapState && state.mapState.map && state.mapState.map.allMaps) ||
      [],
    searchPlace:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.searchPlace) ||
      "",
    filteringWord:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.filteringWord) ||
      "",
    currentLocation: (state.mapState &&
      state.mapState.map &&
      state.mapState.map.currentLocation) || { lat: 0, lng: 0 },
    loginStatus:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.loginStatus) ||
      "",
    loginUserInfo: (state.mapState &&
      state.mapState.map &&
      state.mapState.map.loginUserInfo) || { id: "", uid: "", isAdmin: false },
  };
};

// Reduxの接続を定義
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// コンポーネントのプロップス型
interface MapProps extends PropsFromRedux, RouteComponentProps {
  dispatch: any;
}

// スタイル型
type StylesType = Record<string, any>;

// メインのMapコンポーネント
const Map: React.FC<MapProps> = (props) => {
  const [newMarker, setNewMarker] = useState<Location | null>(null);
  const [editingMarker, setEditingMarker] = useState<MapData | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isStampRallyMode, setIsStampRallyModes] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [zoom, setZoom] = useState<number>(5);
  const [dragged, setDragged] = useState<boolean>(false);
  const [zoomed, setZoomed] = useState<boolean>(false);
  const [currentBounds, setCurrentBounds] = useState<number[]>([]);
  const [currentZoom, setCurrentZoom] = useState<number>(0);
  const [isLoading, setIsLoding] = useState<boolean>(false);
  const [isGeocodeLoading, setIsGeocodeLoding] = useState<boolean>(false);
  const [filteringWord, setFilteringWord] = useState<string>("");
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [isCheckingIn, setIsCheckingIn] = useState<boolean>(false);
  const [isWatchingLocation, setIsWatchingLocation] = useState<boolean>(false);
  const locationWatchIdRef = useRef<number | null>(null);
  const [searchResultMarker, setSearchResultMarker] =
    useState<SearchResultMarkerType | null>(null);
  const googleApiLoadedRef = useRef<boolean>(false);
  const [initialLocationSet, setInitialLocationSet] = useState<boolean>(false);

  // Google Maps APIの読み込み状態を管理するstate
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(
    !!(window.google && window.google.maps)
  );

  // APIロード状態のエラーメッセージ
  const [apiLoadError, setApiLoadError] = useState<string | null>(null);

  // マップ初期化時刻（デバッグ用）
  const [initTime] = useState<string>(new Date().toISOString());

  // 認証フックを使用
  const { user, loginWithTwitter, loginWithGoogle, loginWithEmail, logout } =
    useAuth();

  useEffect(() => {
    const getList = async () => {
      try {
        console.log("マップデータの読み込みを開始します");
        await checkAuth(props.dispatch);
        console.log("ユーザー認証チェック完了");

        console.log("マップデータをフェッチします");
        // 実装を確認し、isInitializeパラメータはtrueで呼び出す
        await listMaps(props.dispatch, true);
        console.log(
          "マップデータのフェッチ完了。件数:",
          props.mapsData?.length || 0
        );
      } catch (error) {
        console.error("初期化中にエラーが発生しました:", error);
        setAlertMessage(
          "データの読み込みに失敗しました。ページを再読み込みしてください。"
        );
      }
    };
    getList();

    // 位置情報の監視を開始
    startLocationTracking();

    // クリーンアップ関数 - コンポーネントがアンマウントされたときに実行
    return () => {
      stopLocationTracking();
    };
  }, [props.dispatch]);

  const { clusters } = useSupercluster({
    points: props.clusters,
    bounds: currentBounds,
    zoom: currentZoom,
    options: { radius: 50, maxZoom: 20 },
  });

  // 位置情報の監視を開始する関数
  const startLocationTracking = () => {
    if (!navigator.geolocation) {
      setAlertMessage("お使いのブラウザは位置情報をサポートしていません。");
      return;
    }

    if (isWatchingLocation) return; // すでに監視中なら何もしない

    try {
      // 最初に一度位置情報を取得
      setIsLoding(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handlePositionUpdate(position);
          setIsLoding(false);
        },
        (error) => {
          handleLocationError(error);
          setIsLoding(false);
        },
        getLocationOptions()
      );

      // 継続的に位置情報を監視
      const watchId = navigator.geolocation.watchPosition(
        handlePositionUpdate,
        handleLocationError,
        getLocationOptions()
      );

      locationWatchIdRef.current = watchId;
      setIsWatchingLocation(true);
      console.log("位置情報の監視を開始しました。Watch ID:", watchId);
    } catch (error) {
      console.error("位置情報の監視開始中にエラーが発生しました:", error);
      setAlertMessage("位置情報の取得に失敗しました。");
      setIsLoding(false);
    }
  };

  // 位置情報の監視を停止する関数
  const stopLocationTracking = () => {
    if (locationWatchIdRef.current !== null) {
      navigator.geolocation.clearWatch(locationWatchIdRef.current);
      locationWatchIdRef.current = null;
      setIsWatchingLocation(false);
      console.log("位置情報の監視を停止しました。");
    }
  };

  // 位置情報オプションを取得する関数
  const getLocationOptions = (): PositionOptions => {
    return {
      enableHighAccuracy: true, // 高精度の位置情報を要求
      timeout: 10000, // 10秒でタイムアウト
      maximumAge: 0, // キャッシュした位置情報を使用しない
    };
  };

  // 位置情報が更新されたときの処理
  const handlePositionUpdate = (position: GeolocationPosition) => {
    const location: Location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setUserLocation(location);

    // 初回のみ現在地を中心に設定
    if (!initialLocationSet) {
      setCurrentLocation(props.dispatch, location);
      setInitialLocationSet(true);
      console.log("初回の位置情報設定完了:", location);
    }
  };

  // 位置情報取得エラー時の処理
  const handleLocationError = (error: GeolocationPositionError) => {
    console.error("位置情報の取得に失敗しました:", error);
    let errorMessage = "位置情報の取得に失敗しました。";

    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage += "位置情報の使用許可を与えてください。";
        break;
      case error.POSITION_UNAVAILABLE:
        errorMessage += "現在位置情報が取得できません。";
        break;
      case error.TIMEOUT:
        errorMessage += "位置情報の取得がタイムアウトしました。";
        break;
      default:
        errorMessage += "不明なエラーが発生しました。";
    }

    setAlertMessage(errorMessage);
  };

  // 2点間の距離を計算する関数（ヒュベニの公式を使用）
  const calculateDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ): number => {
    // 緯度経度をラジアンに変換
    const radLat1 = (lat1 * Math.PI) / 180;
    const radLng1 = (lng1 * Math.PI) / 180;
    const radLat2 = (lat2 * Math.PI) / 180;
    const radLng2 = (lng2 * Math.PI) / 180;

    // 緯度差
    const latDiff = radLat1 - radLat2;
    // 経度差
    const lngDiff = radLng1 - radLng2;
    // 平均緯度
    const latAvg = (radLat1 + radLat2) / 2.0;

    // 赤道半径
    const a = 6378137.0;
    // 第一離心率^2
    const e2 = 0.00669438002301188;
    // 赤道上の子午線曲率半径
    const a1e2 = 6335439.32708317;

    const sinLat = Math.sin(latAvg);
    const W2 = 1.0 - e2 * (sinLat * sinLat);

    // 子午線曲率半径M
    const M = a1e2 / (Math.sqrt(W2) * W2);
    // 卯酉線曲率半径
    const N = a / Math.sqrt(W2);

    const t1 = M * latDiff;
    const t2 = N * Math.cos(latAvg) * lngDiff;

    // 2点間の距離（メートル）
    return Math.sqrt(t1 * t1 + t2 * t2);
  };

  const onClickMap = (map: any) => {
    if (isLoading) return;

    // 管理者権限チェックのデバッグ出力
    console.log("マップクリック時の権限チェック:", {
      loginStatus: props.loginStatus,
      isAdmin: props.loginUserInfo?.isAdmin,
      loginUserInfo: props.loginUserInfo,
    });

    // 管理者のみがマーカーを追加できる
    if (
      props.loginStatus !== LOGIN_STATUS.login ||
      !props.loginUserInfo?.isAdmin
    ) {
      // 権限がない場合は何もせず静かに無視（メッセージ表示なし）
      return;
    }

    setNewMarker({ lat: map.lat, lng: map.lng });
  };

  const onClosePhotoDialog = () => {
    setNewMarker(null);
    setEditingMarker(null);
  };

  // データを再読み込みする関数を追加
  const refreshMapData = async () => {
    try {
      console.log("マップデータを再読み込みします");
      setIsLoding(true);
      await listMaps(props.dispatch, true);
      console.log("マップデータの再読み込み完了");
      setIsLoding(false);
    } catch (error) {
      console.error("データの再読み込み中にエラーが発生しました:", error);
      setAlertMessage("データの再読み込みに失敗しました");
      setIsLoding(false);
    }
  };

  const onDragEnd = () => {
    if (dragged || isLoading) return;
    setDragged(true);
  };

  const onZoomAnimationStart = () => {
    if (zoomed || isLoading) return;
    setZoomed(true);
  };

  const onChangeMap = ({ bounds, zoom }: { bounds: any; zoom: number }) => {
    if (isLoading) return;
    setCurrentBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ]);
    setCurrentZoom(zoom);
  };

  const onChangePlace = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = e.target.value;
    await setSearchPlace(props.dispatch, searchWord);
  };

  // チェックイン処理を実行する関数
  const handleCheckIn = async (map: MapData) => {
    console.log("チェックイン処理開始:", map);
    console.log("ログイン状態:", props.loginStatus);
    console.log("ユーザー情報:", props.loginUserInfo);

    if (!userLocation) {
      setAlertMessage("位置情報を取得中です。しばらくお待ちください。");
      return;
    }

    // ログイン状態の確認方法を修正
    if (props.loginStatus !== LOGIN_STATUS.login) {
      setAlertMessage("チェックインするにはログインが必要です。");
      return;
    }

    setIsCheckingIn(true);
    setIsLoding(true);

    // 現在位置と目的地の距離を計算
    const distance = calculateDistance(
      userLocation.lat,
      userLocation.lng,
      map.lat,
      map.lng
    );

    // チェックイン可能な距離（メートル）
    const CHECKIN_RADIUS = 100;

    // 指定した距離以内ならチェックイン可能
    if (distance <= CHECKIN_RADIUS) {
      try {
        // マップオブジェクトを取得
        const targetMap = props.mapsData.find((m) => m.id === map.id);
        if (targetMap) {
          console.log("チェックイン対象マップ:", targetMap);
          console.log("ログインユーザー情報:", props.loginUserInfo);

          await checkInLocation(
            props.dispatch,
            targetMap,
            props.mapsData,
            props.loginUserInfo
          );
          setAlertMessage(`${map.title || map.name}にチェックインしました！🎉`);
        } else {
          console.error("マップデータが見つかりません。ID:", map.id);
          console.log("利用可能なマップデータ:", props.mapsData);
          setAlertMessage("マップデータが見つかりませんでした。");
        }
      } catch (error) {
        console.error("チェックインに失敗しました:", error);
        setAlertMessage("チェックインに失敗しました。");
      }
    } else {
      setAlertMessage(
        `チェックインするには、もっと近づいてください（現在地点から約${Math.round(
          distance
        )}m離れています）`
      );
    }

    setIsCheckingIn(false);
    setIsLoding(false);
  };

  // マーカークリック時の処理
  const onClickMarker = (e: React.MouseEvent, data: any, id: string) => {
    // クラスターの位置情報をコンソールに出力（デバッグ用）
    console.log("マーカークリック - データ:", data);

    // ログイン状態チェック
    if (props.loginStatus !== LOGIN_STATUS.login) {
      // ログインしていない場合はアラート表示
      window.alert("聖地の詳細を確認するにはログインしてください");
      return;
    }

    // クラスターの位置情報を明示的に抽出
    const markerData = {
      ...data,
      id: id,
      // 位置情報が含まれていない場合に備えて、デフォルト値を設定
      lat: data.lat || 0,
      lng: data.lng || 0,
    };

    console.log("設定するマーカーデータ:", markerData);

    setEditingMarker(markerData as MapData);
  };

  // コンポーネントマウント時にGoogle Maps APIの読み込み状態を確認
  useEffect(() => {
    console.log(`[Map] コンポーネント初期化: ${initTime}`);

    // Google Maps APIが既に読み込まれているか確認
    if (window.google && window.google.maps) {
      console.log("[Map] Google Maps APIは既に読み込まれています");
      setGoogleMapsLoaded(true);
      return;
    }

    console.log("[Map] Google Maps APIをチェック中...");

    // APIロードイベントリスナーを設定
    const handleApiLoaded = () => {
      console.log("[Map] Google Maps API読み込み完了イベントを受信");
      setGoogleMapsLoaded(true);
    };

    // カスタムイベントのリスナーを追加
    window.addEventListener("google-maps-loaded", handleApiLoaded);

    // タイムアウト設定（10秒後にもAPIが読み込まれていなければエラー表示）
    const timeoutId = setTimeout(() => {
      if (!window.google || !window.google.maps) {
        console.error("[Map] Google Maps APIの読み込みタイムアウト");
        setApiLoadError(
          "Google Maps APIの読み込みに失敗しました。ページを再読み込みしてください。"
        );
      }
    }, 10000);

    return () => {
      window.removeEventListener("google-maps-loaded", handleApiLoaded);
      clearTimeout(timeoutId);
    };
  }, [initTime]);

  // GoogleMapReactコンポーネントをレンダリングする前に、APIキーが正しく設定されているか確認
  const apiKey = "AIzaSyCC62Bvln71F53GQQD6qw517IlqZTMMH4E";
  useEffect(() => {
    if (!apiKey) {
      console.error("[Map] Google Maps APIキーが設定されていません");
      setApiLoadError("Google Maps APIキーが設定されていません");
    } else {
      console.log("[Map] 使用するAPIキー:", apiKey.substring(0, 8) + "...");
    }
  }, [apiKey]);

  // 認証プロバイダー選択処理
  const handleProviderSelect = async (provider: string) => {
    console.log(`選択された認証プロバイダー: ${provider}`);
    setIsLoding(true);

    try {
      let success = false;

      switch (provider) {
        case AUTH_PROVIDERS.TWITTER:
          console.log("Twitter認証を開始");
          success = await loginWithTwitter();
          break;
        case AUTH_PROVIDERS.GOOGLE:
          console.log("Google認証を開始");
          success = await loginWithGoogle();
          break;
        case AUTH_PROVIDERS.EMAIL:
          // メール認証はEmailLoginDialogから直接処理されるため、ここでは何もしない
          return;
        default:
          console.warn(`未知の認証プロバイダー: ${provider}`);
          return;
      }

      console.log(`認証結果: ${success ? "成功" : "失敗"}`);

      // 認証成功後にクラスターを更新
      if (success) {
        console.log("認証成功後のクラスター更新");
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 状態更新を待つ
        await checkAuth(props.dispatch);
      }
    } catch (error) {
      console.error("認証エラー:", error);
    } finally {
      setIsLoding(false);
    }
  };

  // ログアウト処理
  const handleLogout = async () => {
    console.log("ログアウト処理開始");
    setIsLoding(true);

    try {
      await logout();
      console.log("ログアウト処理完了");
    } catch (error) {
      console.error("ログアウト処理エラー:", error);
    } finally {
      setIsLoding(false);
    }
  };

  // メールアドレスでのログイン/登録処理
  const handleEmailLogin = async (
    email: string,
    password: string,
    isSignUp: boolean
  ) => {
    console.log(
      `メールアドレスで${isSignUp ? "登録" : "ログイン"}開始: ${email}`
    );
    setIsLoding(true);

    try {
      const success = await loginWithEmail(email, password, isSignUp);
      console.log(`メールアドレス認証結果: ${success ? "成功" : "失敗"}`);

      if (success) {
        console.log("メールアドレス認証成功後のクラスター更新");
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 状態更新を待つ
        await checkAuth(props.dispatch);
      }
    } catch (error) {
      console.error("メールアドレス認証エラー:", error);
    } finally {
      setIsLoding(false);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {/* ロゴ */}
      <div style={{ position: "absolute", zIndex: 100, top: 4, right: "5px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* 地名検索 - 聖地検索と同じスタイルに */}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              marginBottom: "4px",
            }}
          >
            <div style={{ fontSize: 10, color: "salmon", marginBottom: "4px" }}>
              地名検索
            </div>
            <SearchPlace
              style={{ backgroundColor: "transparent", padding: 0, margin: 0 }}
              label=""
              onChangePlace={onChangePlace}
              onClickGeocode={(e: React.MouseEvent, searchPlace: string) => {
                e.preventDefault();
                if (!searchPlace || searchPlace.trim() === "") {
                  setAlertMessage("検索キーワードを入力してください");
                  return;
                }

                // ロード状態をオンにする
                setIsGeocodeLoding(true);
                setAlertMessage("検索中...");

                console.log("検索キーワード:", searchPlace);

                try {
                  // PlacesServiceの初期化（マップのDOM要素が必要）
                  const mapDiv = document.createElement("div");
                  const placesService =
                    new window.google.maps.places.PlacesService(mapDiv);

                  // 検索リクエストを作成
                  const request = {
                    query: searchPlace,
                    fields: ["name", "geometry"],
                    locationBias: {
                      radius: 5000,
                      center: { lat: 35.6812, lng: 139.7671 },
                    }, // 日本（東京）中心
                  };

                  // テキスト検索を実行
                  placesService.textSearch(request, (results, status) => {
                    console.log("Places検索結果:", status, results);

                    if (
                      status ===
                        window.google.maps.places.PlacesServiceStatus.OK &&
                      results &&
                      results.length > 0
                    ) {
                      // 最初の結果の位置情報を取得
                      const location = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                      };

                      console.log("検索結果の位置情報:", location);

                      // 現在位置を更新
                      setCurrentLocation(props.dispatch, location);

                      // 検索結果のマーカーを設定
                      setSearchResultMarker({
                        lat: location.lat,
                        lng: location.lng,
                        name: searchPlace,
                        // 検索結果用のマーカー画像URL
                        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                      });

                      // 地図操作フラグをリセット - 検索結果を中心に表示するため
                      setDragged(false);
                      setZoomed(false);

                      // 更新が反映されるまで少し待つ
                      setTimeout(() => {
                        // ズームレベルを変更
                        setZoom(15);

                        // 成功メッセージを表示
                        setAlertMessage(`${searchPlace}が見つかりました`);

                        // ローディング状態を解除
                        setIsGeocodeLoding(false);
                      }, 300);
                    } else {
                      // エラーを詳細に表示
                      console.error("Places検索失敗:", status);

                      let errorMessage = "場所が見つかりませんでした";

                      if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                          .ZERO_RESULTS
                      ) {
                        errorMessage = "検索結果が見つかりませんでした";
                      } else if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                          .OVER_QUERY_LIMIT
                      ) {
                        errorMessage =
                          "検索の制限回数を超えました。しばらく待ってから再試行してください";
                      } else if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                          .REQUEST_DENIED
                      ) {
                        errorMessage =
                          "リクエストが拒否されました。APIキーを確認してください";
                      } else if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus
                          .INVALID_REQUEST
                      ) {
                        errorMessage = "無効なリクエストです";
                      }

                      // 検索結果のマーカーをクリア
                      setSearchResultMarker(null);
                      setAlertMessage(errorMessage);
                      setIsGeocodeLoding(false);
                    }
                  });
                } catch (error) {
                  console.error("地名検索の例外エラー:", error);
                  // 検索結果のマーカーをクリア
                  setSearchResultMarker(null);
                  setAlertMessage("検索処理中にエラーが発生しました");
                  setIsGeocodeLoding(false);
                }
              }}
              searchPlace={props.searchPlace}
              isLoading={isGeocodeLoading}
            />
          </div>
          <div style={{ height: "4px" }}></div>

          {/* 聖地検索 - 地名検索の下に配置 */}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              marginBottom: "4px",
            }}
          >
            <div style={{ fontSize: 10, color: "salmon", marginBottom: "4px" }}>
              聖地検索
            </div>
            <TextField
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const filteringWord = e.target.value;
                setFilteringWord(filteringWord);
                let maps = [...props.allMaps];
                maps = maps.filter((data) => {
                  if (
                    data.name.indexOf(filteringWord) !== -1 ||
                    data.title.indexOf(filteringWord) !== -1 ||
                    data.explanation.indexOf(filteringWord) !== -1 ||
                    !filteringWord
                  ) {
                    return true;
                  }
                  return false;
                });
                setFilteringMaps(
                  props.dispatch,
                  maps,
                  filteringWord,
                  props.loginUserInfo
                );
              }}
              value={filteringWord || ""}
              size="small"
              style={{ width: "100%" }}
            />
          </div>

          <a
            style={{ fontSize: 12, color: "white", textDecoration: "none" }}
            href="https://twitter.com/kakikukekoichi"
          >
            <button
              style={{
                backgroundColor: "#3f51b5",
                color: "white",
                padding: "4px 8px",
                border: "none",
                borderRadius: "4px",
                fontSize: "12px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              @kakikukekoichi(開発者)
            </button>
          </a>
        </div>
      </div>

      {/* ログインボタン - 画面右下に配置 */}
      <div
        style={{
          position: "absolute",
          zIndex: 1000,
          bottom: "20px",
          right: "10px",
        }}
      >
        <LoginButton
          isLoggedIn={props.loginStatus === LOGIN_STATUS.login}
          userDisplayName={props.loginUserInfo?.displayName}
          onLoginClick={handleLogout}
          onProviderSelect={handleProviderSelect}
          onEmailLogin={handleEmailLogin}
        />
      </div>

      <PhotoDropDialog
        open={!!newMarker || !!editingMarker}
        editingMarker={editingMarker}
        onClose={onClosePhotoDialog}
        onClickAddPhoto={(
          name: string,
          title: string,
          explanation: string,
          file: File
        ) => {
          if (isLoading) return;
          setIsLoding(true);
          setAlertMessage("アップロード中...");
          if (!!newMarker) {
            // 新規追加
            const map = {
              name,
              title,
              explanation,
              lat: newMarker.lat,
              lng: newMarker.lng,
            } as MapData;
            registMaps(
              true,
              map,
              null,
              file,
              props.dispatch,
              props.mapsData,
              props.loginUserInfo
            ).then((success) => {
              if (success) {
                setAlertMessage("マーカーを追加しました");
                // データを再読み込み
                refreshMapData();
              } else {
                setAlertMessage("マーカーの追加に失敗しました");
                setIsLoding(false);
              }
              setNewMarker(null);
            });
          } else if (!!editingMarker) {
            // 編集
            const map = {
              ...editingMarker,
              name,
              title,
              explanation,
            };

            // ダミーファイルの場合は実ファイルとして扱わない
            const isDummyFile = file.name === "dummy" && file.size === 0;
            console.log("編集時のファイル情報:", {
              fileName: file.name,
              fileSize: file.size,
              isDummyFile,
            });

            registMaps(
              false,
              map,
              editingMarker,
              file,
              props.dispatch,
              props.mapsData,
              props.loginUserInfo
            ).then((success) => {
              if (success) {
                setAlertMessage("マーカーを更新しました");
                // データを再読み込み
                refreshMapData();
              } else {
                setAlertMessage("マーカーの更新に失敗しました");
                setIsLoding(false);
              }
              setEditingMarker(null);
            });
          }
        }}
        onDelete={() => {
          if (isLoading) return;
          setIsLoding(true);
          setAlertMessage("削除中...");
          deleteMap(props.dispatch, editingMarker).then((success) => {
            if (success) {
              setAlertMessage("マーカーを削除しました");
              // データを再読み込み
              refreshMapData();
            } else {
              setAlertMessage("マーカーの削除に失敗しました");
              setIsLoding(false);
            }
            // 両方の状態をリセットしてダイアログを確実に閉じる
            setEditingMarker(null);
            setNewMarker(null);
          });
        }}
        onCheckIn={() => handleCheckIn(editingMarker)}
        isLoading={isLoading}
        loginUserInfo={props.loginUserInfo}
        isAdmin={props.loginUserInfo?.isAdmin || false}
        isEditing={!!editingMarker}
        isLogin={props.loginStatus === LOGIN_STATUS.login}
        isCheckedIn={editingMarker ? editingMarker.isCheckedIn : false}
        name={editingMarker ? editingMarker.name : ""}
        title={editingMarker ? editingMarker.title : ""}
        explanation={editingMarker ? editingMarker.explanation : ""}
        imgSrc={editingMarker ? editingMarker.url : null}
        isCreateUserAction={
          props.loginUserInfo?.isAdmin ||
          (props.loginStatus === LOGIN_STATUS.login &&
            props.loginUserInfo &&
            editingMarker &&
            editingMarker.createUserId === props.loginUserInfo.uid)
        }
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        onClose={() => {
          setAlertMessage("");
        }}
        autoHideDuration={6000}
        message={alertMessage}
      >
        <div
          style={{
            color: "snow",
            backgroundColor: "#323232",
            borderRadius: 4,
            width: "100%",
            textAlign: "center",
            padding: "6px 20px",
            fontSize: 14,
          }}
        >
          {alertMessage}
        </div>
      </Snackbar>

      {/* マップ - 画面全体に表示 */}
      <div
        style={{
          height: "100vh",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Google Maps APIの読み込みエラーがある場合のフォールバック表示 */}
        {apiLoadError && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "rgba(255, 255, 255, 0.9)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              zIndex: 999,
              textAlign: "center",
              maxWidth: "80%",
            }}
          >
            <h3>マップ読み込みエラー</h3>
            <p>{apiLoadError}</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              ページを再読み込み
            </Button>
          </div>
        )}

        {/* デバッグ情報（開発環境のみ表示） */}
        {process.env.NODE_ENV === "development" && (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              zIndex: 999,
              background: "rgba(0,0,0,0.7)",
              color: "white",
              padding: "5px 10px",
              fontSize: "12px",
              borderRadius: "4px",
            }}
          >
            API: {googleMapsLoaded ? "読込済" : "未読込"} | 初期化:{" "}
            {initTime.substring(11, 19)}
          </div>
        )}

        <GoogleMapReact
          bootstrapURLKeys={{
            key: apiKey,
            libraries: ["places"],
          }}
          defaultCenter={defaultCenter}
          center={
            dragged || zoomed
              ? undefined
              : props.searchPlace && !!searchResultMarker
              ? { lat: searchResultMarker.lat, lng: searchResultMarker.lng }
              : props.currentLocation &&
                typeof props.currentLocation.lat === "number" &&
                typeof props.currentLocation.lng === "number" &&
                !initialLocationSet
              ? (props.currentLocation as Location)
              : defaultCenter
          }
          defaultZoom={8}
          zoom={zoom}
          onChange={onChangeMap}
          onClick={
            isStampRallyMode ||
            isLoading ||
            !props.loginStatus ||
            props.loginStatus !== LOGIN_STATUS.login
              ? () => {}
              : onClickMap
          }
          onDragEnd={onDragEnd}
          onZoomAnimationStart={onZoomAnimationStart}
          onGoogleApiLoaded={({ map, maps }) => {
            console.log("Google Map API読み込み完了");
            googleApiLoadedRef.current = true;
            // マップロード完了後に再度データ確認
            if (props.mapsData.length === 0) {
              console.log(
                "マップデータが読み込まれていないため、再フェッチを試みます"
              );
              listMaps(props.dispatch, true);
            }
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          options={{
            fullscreenControl: false,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            clickableIcons: true,
            keyboardShortcuts: true,
            scrollwheel: true,
            disableDoubleClickZoom: false,
            gestureHandling: "greedy",
          }}
        >
          {clusters.map((cluster: any, i: number) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster } = cluster.properties;
            if (isCluster) {
              return (
                <Cluster
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  cluster={cluster}
                />
              );
            }
            return (
              <Place
                key={i}
                lat={latitude}
                lng={longitude}
                isCreateUser={props.loginUserInfo?.isAdmin || false}
                name={cluster.properties.name}
                url={cluster.properties.url}
                isStampRallyMode={isStampRallyMode}
                isStamped={cluster.properties.isStamped || false}
                isCheckedIn={cluster.properties.isCheckedIn || false}
                onClickMarker={(e: React.MouseEvent) =>
                  isStampRallyMode
                    ? console.log("スタンプモードでのクリック") // スタンプモードの実装は別途必要
                    : onClickMarker(
                        e,
                        cluster.properties,
                        cluster.properties.id
                      )
                }
              />
            );
          })}
          {/* ユーザーの現在位置を表示 */}
          {userLocation && (
            <div
              // @ts-ignore - GoogleMapReactの子コンポーネントにはlatとlngが必要
              lat={userLocation.lat}
              lng={userLocation.lng}
              style={{
                width: 16,
                height: 16,
                backgroundColor: "#4285F4",
                borderRadius: "50%",
                border: "2px solid white",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
                transform: "translate(-50%, -50%)",
                zIndex: 10,
              }}
            />
          )}
          {/* 検索結果のマーカーを表示 */}
          {searchResultMarker && (
            <SearchResultMarker
              lat={searchResultMarker.lat}
              lng={searchResultMarker.lng}
              name={searchResultMarker.name}
              url={searchResultMarker.url}
            />
          )}
        </GoogleMapReact>

        {/* 位置情報取得中の表示 */}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "10px",
              zIndex: 10,
              backgroundColor: "rgba(66, 133, 244, 0.8)",
              color: "white",
              padding: "8px 12px",
              borderRadius: "4px",
              fontSize: "14px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            位置情報取得中...
          </div>
        )}
      </div>
    </div>
  );
};

const styles: StylesType = {};

// withRouterとwithStylesの型エラーを回避するために、順番を変更
export default connector(withStyles(styles)(withRouter(Map)));
