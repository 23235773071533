import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { MapState } from "../../../types/map";
import { AnyAction } from "redux";

const defaultState: MapState = {
  mapsData: [],
  clusters: [],
  allMaps: [],
  searchPlace: "",
  filteringWord: "",
  currentLocation: {},
  loginStatus: "",
  loginUserInfo: {
    id: "",
    isAdmin: false,
  },
};

const map = (state = defaultState, action: AnyAction): MapState => {
  switch (action.type) {
    case "LIST_MAPS":
      return {
        ...state,
        allMaps: action.payload.mapsData,
        mapsData: action.payload.mapsData,
      };
    case "SET_SEARCH_PLACE":
      return {
        ...state,
        searchPlace: action.payload.searchPlace,
      };
    case "SET_CURRENT_LOCATION":
      return {
        ...state,
        currentLocation: action.payload.currentLocation,
      };
    case "SET_STAMP":
      return {
        ...state,
        mapsData: [...action.payload.mapsData],
      };
    case "SET_CHECK_IN":
      return {
        ...state,
        mapsData: [...action.payload.mapsData],
      };
    case "SET_FILTERING_MAPS":
      return {
        ...state,
        mapsData: [...action.payload.mapsData],
        filteringWord: action.payload.filteringWord,
      };
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        loginStatus: action.payload.loginStatus,
        loginUserInfo:
          action.payload.loginUserInfo || defaultState.loginUserInfo,
      };
    case "SET_LOGIN_USER_INFO":
      return {
        ...state,
        loginUserInfo: action.payload.loginUserInfo,
      };
    case "SET_CLUSTERS":
      return {
        ...state,
        clusters: action.payload.clusters,
      };
    default:
      return state;
  }
};

export default combineReducers({
  map,
  form: formReducer,
});
