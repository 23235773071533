import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import * as reducers from "./ducks"; // import all reducers from ducks/index.js
import logger from "redux-logger";
import thunk from "redux-thunk"; // Redux Thunkをインポート
import { RootState as AppRootState } from "../types/redux";

// Redux DevToolsの拡張機能の型定義
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

function store(initialState = {}) {
  console.log("Reduxストア初期化中...");
  console.log("利用可能なリデューサー:", Object.keys(reducers));

  try {
    const rootReducer = combineReducers(reducers);
    console.log("ルートリデューサー作成完了");

    // Redux DevTools拡張機能を使用するためのコンポーザー
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    console.log("Redux DevTools設定完了");

    // テスト環境ではロガーを使用しない
    const isTestEnv = process.env.REACT_APP_ENV === 'test';
    const middlewares = isTestEnv ? [thunk] : [thunk, logger];

    console.log("ストア作成開始");
    const createdStore = createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares))
    );
    console.log("ストア作成完了");
    return createdStore;
  } catch (error) {
    console.error("Reduxストア初期化エラー:", error);
    throw error;
  }
}

export type RootState = AppRootState;
export type AppDispatch = ReturnType<typeof store>["dispatch"];

export default store;
