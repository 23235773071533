/**
 * index.ts
 *
 * ストリーミングスケジュールのリデューサー
 * 配信スケジュールのRedux状態管理を担当します
 */

import {
  STREAMING_FETCH_START,
  SET_STREAMING_SCHEDULES,
  FETCH_STREAMING_SCHEDULES_FAILURE,
  StreamingActionTypes,
} from "./types";
import { StreamingState } from "../../../types/streaming";

// 初期状態
const initialState: StreamingState = {
  schedules: [],
  nextSchedule: null,
  loading: false,
  error: null,
  lastFetched: null,
};

/**
 * ストリーミングリデューサー
 * 配信スケジュール関連のアクションを処理します
 */
const streamingReducer = (
  state: StreamingState = initialState,
  action: StreamingActionTypes
): StreamingState => {
  switch (action.type) {
    case STREAMING_FETCH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SET_STREAMING_SCHEDULES:
      return {
        ...state,
        schedules: action.payload.schedules,
        nextSchedule: action.payload.nextSchedule,
        lastFetched: action.payload.lastFetched,
        loading: false,
        error: null,
      };

    case FETCH_STREAMING_SCHEDULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default streamingReducer;
