import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Typography } from "@material-ui/core";
import { FileDropzoneProps, FileWithPreview } from "./types";

/**
 * ファイルのドロップゾーンコンポーネント
 * 画像ファイルのドラッグ&ドロップとプレビュー表示を担当
 */
const FileDropzone: React.FC<FileDropzoneProps> = ({
  onDrop,
  file,
  disabled = false,
}) => {
  // ファイルドロップのコールバック
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const newFile = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }) as FileWithPreview;

      onDrop([newFile]);
    },
    [onDrop]
  );

  // Dropzoneの設定
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: "image/jpeg, image/png, image/gif",
    maxFiles: 1,
    disabled,
  });

  // コンポーネント破棄時にプレビューURLを解放
  useEffect(() => {
    return () => {
      if (file?.preview) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  return (
    <div
      {...getRootProps({
        style: {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderWidth: 2,
          borderRadius: 2,
          borderColor: isDragActive ? "#2196f3" : "#eeeeee",
          borderStyle: "dashed",
          backgroundColor: isDragActive ? "#e3f2fd" : "#fafafa",
          color: "#bdbdbd",
          outline: "none",
          transition: "border .24s ease-in-out",
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? 0.6 : 1,
        },
      })}
    >
      <input {...getInputProps()} />

      {file ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            src={file.preview}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "200px",
              objectFit: "contain",
              marginBottom: "10px",
            }}
          />
          <Typography variant="caption" display="block">
            {file.name} ({Math.round(file.size / 1024)} KB)
          </Typography>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography>
            {isDragActive
              ? "画像をここにドロップ"
              : "クリック、またはドラッグ&ドロップで画像をアップロード"}
          </Typography>
          <Typography variant="caption" display="block" color="textSecondary">
            JPG, PNG, GIF形式がサポートされています
          </Typography>
        </div>
      )}
    </div>
  );
};

export default FileDropzone;
