/**
 * types.ts
 *
 * ストリーミングモジュールのアクション型定義
 * Reduxアクションタイプの定数と型定義を提供します
 */

import { StreamingSchedule } from "../../../types/streaming";

// アクションタイプ定数
export const STREAMING_FETCH_START = "streaming/FETCH_START";
export const SET_STREAMING_SCHEDULES = "streaming/SET_SCHEDULES";
export const FETCH_STREAMING_SCHEDULES_FAILURE = "streaming/FETCH_FAILURE";

// アクション型定義
export interface FetchStreamingSchedulesStartAction {
  type: typeof STREAMING_FETCH_START;
}

export interface SetStreamingSchedulesAction {
  type: typeof SET_STREAMING_SCHEDULES;
  payload: {
    schedules: StreamingSchedule[];
    nextSchedule: StreamingSchedule | null;
    lastFetched: string;
  };
}

export interface FetchStreamingSchedulesFailureAction {
  type: typeof FETCH_STREAMING_SCHEDULES_FAILURE;
  payload: {
    error: string;
  };
}

// ユニオン型
export type StreamingActionTypes =
  | FetchStreamingSchedulesStartAction
  | SetStreamingSchedulesAction
  | FetchStreamingSchedulesFailureAction;
