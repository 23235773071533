import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  User,
  UserCredential,
} from "firebase/auth";
import { Dispatch } from "redux";
import { MapData, MapState, Cluster } from "../../../types/map";

export const LOGIN_STATUS = { login: "login", logout: "logout" };

interface UserData {
  id: string;
  uid?: string;
  displayName?: string;
  photoURL?: string;
  isAdmin?: boolean;
  [key: string]: any;
}

export const loginOrLogoutWithTwitter = async (
  dispatch: Dispatch,
  isLogin: boolean,
  mapsData: MapData[]
): Promise<boolean> => {
  try {
    console.log(`loginOrLogoutWithTwitter開始: isLogin=${isLogin}`);
    let success = false;
    const auth = getAuth();
    // Googleログインを呼び出すためのボタンを設定します
    var provider = new GoogleAuthProvider();
    if (!isLogin) {
      console.log("ログアウト処理実行");
      auth.signOut();
      dispatch(setloginStatusAction(LOGIN_STATUS.logout, null));
      console.log("ログアウト完了 & 状態更新");
      return true;
    }

    console.log("Googleログイン処理開始");
    success = await signInWithPopup(auth, provider)
      .then(async (result: UserCredential) => {
        console.log("Googleログイン成功");
        // twitterで取得した名前とID
        const displayName = result.user && result.user.displayName;
        const uid = result.user && result.user.uid;
        console.log(`ユーザー情報: displayName=${displayName}, uid=${uid}`);

        const usersRef = collection(db, "users");
        const usersSnap = await getDocs(usersRef);

        // 登録済みのユーザー情報
        const usersData: UserData[] = [];
        usersSnap.forEach((user) => {
          if (user.exists()) {
            usersData.push({ ...user.data(), id: user.id });
          }
        });
        const userData = usersData.find((user) => user.uid === uid);
        console.log(
          "既存ユーザー検索結果:",
          userData ? "ユーザー見つかりました" : "新規ユーザー"
        );

        // 現在のユーザー数を取得
        const userCount = usersData.length;
        console.log(`現在のユーザー数: ${userCount}`);

        let userRef: DocumentReference<DocumentData> | null = null;
        let isAdmin = false;

        if (!userData) {
          // 未登録の場合
          console.log("新規ユーザー登録");

          // 最初のユーザーに管理者権限を付与（または他の条件で決定）
          isAdmin = userCount === 0;
          console.log(`管理者権限付与: ${isAdmin ? "はい" : "いいえ"}`);

          userRef = await addDoc(usersRef, {
            displayName: displayName,
            uid: uid,
            isAdmin: isAdmin,
          });
          console.log("ユーザー登録完了:", userRef.id);
        } else if (userData && userData.displayName !== displayName) {
          // 名前が変わっていれば更新（管理者権限は維持）
          console.log("ユーザー名更新");
          userRef = doc(db, "users", userData.id);
          await updateDoc(userRef, {
            displayName: displayName,
          });
          console.log("ユーザー名更新完了");
          isAdmin = userData.isAdmin || false;
        } else {
          // 既存ユーザーで名前も変わっていない場合
          console.log("既存ユーザーそのまま使用");
          userRef = doc(db, "users", userData.id);
          isAdmin = userData.isAdmin || false;
        }

        if (isLogin) {
          console.log("クラスター取得開始");
          await getClusters(dispatch, mapsData, null, {
            displayName: displayName,
            uid: uid,
            id: userRef.id,
            isAdmin: isAdmin,
          });
          console.log("クラスター取得完了");
        }

        console.log("ログイン状態更新");
        dispatch(
          setloginStatusAction(LOGIN_STATUS.login, {
            displayName: displayName,
            uid: uid,
            id: userRef.id,
            isAdmin: isAdmin,
          })
        );
        console.log("ログイン処理完了");
        return true;
      })
      .catch((e) => {
        console.error("Googleログインエラー:", e);
        //popupを閉じた際もエラーになるのでここでreturn falseはしない
        return false;
      });
    console.log("ログインプロセス結果:", success);
    return !!success;
  } catch (e) {
    console.error("loginOrLogoutWithTwitter エラー:", e);
    return false;
  }
};

export const getClusters = (
  dispatch: Dispatch,
  mapsData: MapData[],
  stampedId: string | null,
  loginUserInfo: UserData | null
): void => {
  //stampedIdがあるとき→スタンプ押下時のみ
  console.log("getClusters: クラスター生成開始", {
    mapsDataCount: mapsData.length,
    loginUserInfo,
  });

  const clusters = mapsData.map((data) => {
    let isStamped = false;
    let isCheckedIn = false;
    const loginUid = !!loginUserInfo ? loginUserInfo.uid : "";

    // スタンプ情報の確認
    if (
      (data.stampedUids || []).includes(loginUid || "") &&
      (stampedId === data.id || !!data.stampedTimeStamp)
    ) {
      isStamped = true;
    }

    // チェックイン情報の確認
    if ((data.checkedInUids || []).includes(loginUid || "")) {
      isCheckedIn = true;
    }

    const lat = parseFloat(data.lat.toString());
    const lng = parseFloat(data.lng.toString());

    // URLの検証と正規化
    let imageUrl = data.url;

    // URLの存在確認と適切なフォールバック
    if (
      !imageUrl ||
      imageUrl.includes("undefined") ||
      imageUrl.includes("null")
    ) {
      console.warn(`getClusters: マップID ${data.id} のURLが未設定/無効です`);

      // フォールバックとしてurlSourceを使用
      if (data.urlSource && !data.urlSource.includes("placeholder")) {
        console.log(`getClusters: マップID ${data.id} はurlSourceを使用します`);
        imageUrl = data.urlSource;
      } else {
        // 両方ない場合はプレースホルダー
        imageUrl = "https://via.placeholder.com/40?text=未設定";
      }
    }

    // 特定のサイズ制限によるプレースホルダー画像かチェック
    if (imageUrl.includes("placeholder")) {
      console.warn(
        `getClusters: マップID ${data.id} はプレースホルダー画像です`
      );
    }

    return {
      type: "Feature",
      properties: {
        cluster: false,
        id: data.id,
        name: data.name || "",
        title: data.title || "",
        url: imageUrl,
        isStamped: isStamped,
        isCheckedIn: isCheckedIn,
        explanation: data.explanation || "",
        createUserId: data.createUserId || "",
        lat: lat,
        lng: lng,
      },
      geometry: {
        type: "Point",
        coordinates: [lng, lat] as [number, number],
      },
    };
  });

  console.log(
    `getClusters: ${clusters.length}件のクラスターデータを生成しました`
  );
  dispatch(setClustersAction(clusters));
};

export const setFilteringMaps = async (
  dispatch: Dispatch,
  mapsData: MapData[],
  filteringWord: string,
  loginUserInfo: UserData | null
): Promise<void> => {
  try {
    await getClusters(dispatch, mapsData, null, loginUserInfo);
    dispatch(setFilteringMapsAction(mapsData, filteringWord));
  } catch (e) {
    console.dir(e);
  }
};

// アクション作成関数
const setFilteringMapsAction = (mapsData: MapData[], filteringWord: string) => {
  return {
    type: "SET_FILTERING_MAPS",
    payload: {
      mapsData: mapsData,
      filteringWord: filteringWord,
    },
  };
};

const setloginStatusAction = (
  loginStatus: string,
  loginUserInfo: UserData | null
) => {
  return {
    type: "SET_LOGIN_STATUS",
    payload: {
      loginStatus: loginStatus,
      loginUserInfo: loginUserInfo,
    },
  };
};

const setClustersAction = (clusters: any[]) => {
  return {
    type: "SET_CLUSTERS",
    payload: {
      clusters: clusters,
    },
  };
};

// チェックイン用アクション
const setCheckInAction = (mapsData: MapData[]) => {
  return {
    type: "SET_CHECK_IN",
    payload: {
      mapsData,
    },
  };
};

export const listMaps = async (
  dispatch: Dispatch,
  isInitialize: boolean = false
): Promise<void> => {
  try {
    console.log("listMaps: マップデータの取得を開始します");

    // ステップ1: Firestoreからマップデータ取得
    const mapsRef = collection(db, "maps");
    const mapsSnap = await getDocs(mapsRef);
    const mapsData: MapData[] = [];

    console.log(
      `listMaps: Firestoreから${mapsSnap.size}件のドキュメントを取得しました`
    );

    mapsSnap.forEach((map) => {
      if (map.exists()) {
        mapsData.push({ ...map.data(), id: map.id } as MapData);
      }
    });

    console.log(`listMaps: 有効なマップデータ ${mapsData.length}件`);

    // 更新前に既存のデータとURLを保存（後で使用するため）
    let existingUrlMap = new Map<string, string>();
    try {
      // Redux状態からデータを取得する代わりに、画面に表示されているすべてのimg要素からURLを取得
      if (typeof document !== "undefined") {
        const imgElements = document.querySelectorAll("img");
        imgElements.forEach((img) => {
          const src = img.getAttribute("src");
          const alt = img.getAttribute("alt");

          // altタグに画像IDが含まれている場合、それをマップIDとして使用
          if (src && alt && !src.includes("placeholder")) {
            const parts = alt.split("-");
            if (parts.length > 0) {
              const potentialId = parts[0].trim();
              if (potentialId && potentialId.length > 10) {
                // IDらしき長さがあるか
                existingUrlMap.set(potentialId, src);
                console.log(`listMaps: DOM画像URL保存: ID=${potentialId}`);
              }
            }
          }
        });
      }
    } catch (domError) {
      console.warn("listMaps: DOM操作エラー:", domError);
    }

    // ステップ2: 既存のURLオブジェクトの解放（メモリリーク防止）
    try {
      // 現在のReduxストアからデータを取得する仕組みがないため、ローカル変数を使用
      const existingData: MapData[] = []; // 実際はReduxストアから取得

      existingData.forEach((item) => {
        if (item.url && item.url.startsWith("blob:")) {
          try {
            URL.revokeObjectURL(item.url);
            console.log(`listMaps: BlobURL解放: ${item.url}`);
          } catch (revokeError) {
            console.warn(`listMaps: URL解放エラー: ${item.url}`, revokeError);
          }
        }
      });
    } catch (memoryError) {
      console.warn("listMaps: メモリ解放エラー:", memoryError);
    }

    // ステップ3: 新しいデータの準備
    const newData = [...mapsData];
    const storage = getStorage();
    const timestamp = new Date().getTime();

    // ステップ4: 画像URL取得（並列処理）
    const imagePromises = mapsData.map(async (data, i) => {
      try {
        // ベーシックなデータ検証
        if (!data.id || !data.name) {
          console.error(
            `listMaps: 無効なマップデータ - ID: ${data.id}, Name: ${data.name}`
          );
          newData[
            i
          ].url = `https://via.placeholder.com/150?text=無効なデータ&t=${timestamp}`;
          return;
        }

        // 既存のURLを先に設定（あれば）
        if (existingUrlMap.has(data.id)) {
          const existingUrl = existingUrlMap.get(data.id);
          if (existingUrl) {
            console.log(`listMaps: 既存URL使用: ID=${data.id}`);
            newData[i].url = existingUrl;
            newData[i].urlSource = existingUrl;
            // 既存のURLがある場合は処理をスキップ
            return;
          }
        }

        console.log(`listMaps: マップID: ${data.id} の画像処理開始`);

        // ストレージパスの構築
        const imagePath = `${data.id}/${data.name}`;
        const imageRef = ref(storage, imagePath);

        try {
          // Firebase Storageから直接URLを取得
          let downloadUrl;
          try {
            downloadUrl = await getDownloadURL(imageRef);
            console.log(
              `listMaps: 現在の名前で画像URL取得成功: ${data.id}/${data.name}`
            );
          } catch (currentNameError) {
            console.warn(
              `listMaps: 現在の名前での画像取得に失敗: ${data.id}/${data.name}`
            );

            // 古い名前情報があれば試行
            if (data.oldName && data.oldName !== data.name) {
              console.log(
                `listMaps: 古い名前で画像取得を試行: ${data.id}/${data.oldName}`
              );
              const oldImageRef = ref(storage, `${data.id}/${data.oldName}`);
              try {
                downloadUrl = await getDownloadURL(oldImageRef);
                console.log(
                  `listMaps: 古い名前で画像URL取得成功: ${data.id}/${data.oldName}`
                );

                // 成功した場合、今後のために現在の名前でもファイルを保存する
                try {
                  // ファイルをダウンロード
                  const response = await fetch(downloadUrl);
                  if (response.ok) {
                    const fileBlob = await response.blob();
                    if (fileBlob.size > 0) {
                      // 現在の名前で再アップロード（非同期で行い、結果を待たない）
                      console.log(
                        `listMaps: 同期のため現在の名前でも保存します: ${data.id}/${data.name}`
                      );
                      const newStorageRef = ref(
                        storage,
                        `${data.id}/${data.name}`
                      );
                      uploadBytes(newStorageRef, fileBlob)
                        .then(() => {
                          console.log(
                            `listMaps: 同期アップロード完了: ${data.id}/${data.name}`
                          );
                        })
                        .catch((syncError) => {
                          console.warn(
                            `listMaps: 同期アップロードエラー: ${data.id}/${data.name}`,
                            syncError
                          );
                        });
                    }
                  }
                } catch (syncError) {
                  console.warn(
                    `listMaps: ファイル同期処理エラー (無視可): ${data.id}`,
                    syncError
                  );
                }
              } catch (oldNameError) {
                console.error(
                  `listMaps: 古い名前でも画像取得に失敗: ${data.id}/${data.oldName}`
                );
                throw oldNameError; // 次のcatchブロックへ
              }
            } else {
              console.warn(
                `listMaps: 古い名前情報なし または 名前変更なし: ${data.id}`
              );
              throw currentNameError; // 次のcatchブロックへ
            }
          }

          // URL直接設定アプローチ - キャッシュバスターつき
          const randomSuffix = Math.random().toString(36).substring(7);
          // 直接URLを設定（fetchやBlobを使わない）
          newData[i].url = `${downloadUrl}?t=${timestamp}&r=${randomSuffix}`;
          newData[i].urlSource = downloadUrl; // 元のURLも保存

          console.log(`listMaps: URL直接設定完了 - ID: ${data.id}`);
        } catch (imageError) {
          console.warn(
            `listMaps: 画像URL取得エラー - ID: ${data.id}:`,
            imageError
          );

          // エラー時のフォールバック: プレースホルダー画像を使用
          try {
            console.log(`listMaps: プレースホルダー使用: ID=${data.id}`);
            newData[
              i
            ].url = `https://via.placeholder.com/150?text=Error&t=${new Date().getTime()}`;
            newData[i].urlSource = "";
          } catch (fallbackError) {
            console.error(
              `listMaps: プレースホルダー設定エラー:`,
              fallbackError
            );
          }
        }
      } catch (dataError) {
        console.error(
          `listMaps: データ処理エラー - インデックス: ${i}:`,
          dataError
        );
        if (newData[i]) {
          newData[
            i
          ].url = `https://via.placeholder.com/150?text=Error&t=${timestamp}`;
          newData[i].urlSource = "";
        }
      }
    });

    // ステップ5: すべての画像処理の完了を待機
    await Promise.all(imagePromises);
    console.log(`listMaps: ${mapsData.length}件のマップデータの画像処理完了`);

    // ステップ6: データをReduxストアに反映
    console.log(
      `listMaps: Reduxストアにデータを更新します - ${newData.length}件`
    );
    dispatch(listMapsAction(newData));

    // ステップ7: 必要に応じてクラスター初期化（指定された場合）
    if (isInitialize) {
      console.log("listMaps: クラスター初期化を実行します");

      try {
        // 現在のユーザー認証状態を取得
        const auth = getAuth();
        const user = auth.currentUser;
        let userInfo: UserData | null = null;

        if (user) {
          console.log(
            `listMaps: 認証済みユーザーでクラスター初期化 - UID: ${user.uid}`
          );

          try {
            // ユーザー情報をFirestoreから取得
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("uid", "==", user.uid));
            const usersSnap = await getDocs(q);

            if (!usersSnap.empty) {
              const userDoc = usersSnap.docs[0];
              userInfo = {
                id: userDoc.id,
                uid: user.uid,
                ...userDoc.data(),
              };
              console.log("listMaps: ユーザー情報取得成功:");
            } else {
              console.warn(
                `listMaps: ユーザー情報が見つかりません - UID: ${user.uid}`
              );
            }
          } catch (userError) {
            console.error("listMaps: ユーザー情報取得エラー:", userError);
          }
        } else {
          console.log("listMaps: 未認証状態でクラスター初期化");
        }

        // 画像URLを含む最新のデータでクラスターを生成
        await getClusters(dispatch, newData, null, userInfo);
        console.log("listMaps: クラスター初期化完了");
      } catch (clusterError) {
        console.error("listMaps: クラスター初期化エラー:", clusterError);
      }
    }

    console.log("listMaps: 全処理完了");
  } catch (e) {
    console.error("listMaps 総合エラー:", e);
  }
};

// アクション作成関数
const listMapsAction = (mapsData: MapData[]) => {
  return {
    type: "LIST_MAPS",
    payload: {
      mapsData: mapsData,
    },
  };
};

export const registMaps = async (
  isNew: boolean,
  data: MapData,
  oldData: MapData | null,
  file: File,
  dispatch: Dispatch,
  mapsData: MapData[],
  loginUserInfo: UserData | null
): Promise<boolean> => {
  try {
    // ファイル判定をここで一度行う
    const isDummyFile = file.name === "dummy" && file.size === 0;
    const isRealFile = !isDummyFile;

    console.log("registMaps: プロセス開始", {
      isNew,
      dataId: data.id,
      dataName: data.name,
      dataTitle: data.title,
      hasUrl: !!data.url,
      oldDataId: oldData?.id,
      oldDataName: oldData?.name,
      oldDataTitle: oldData?.title,
      hasOldUrl: !!oldData?.url,
      fileName: file?.name,
      fileSize: file?.size,
      isDummyFile,
      isRealFile,
    });

    let success = false;
    const storage = getStorage();
    const mapsRef = collection(db, "maps");

    // ユーザー情報のログ（デバッグ用）
    console.log(
      "registMaps: ログインユーザー情報:",
      loginUserInfo?.uid || "未ログイン"
    );

    if (isNew) {
      // 【新規作成処理】
      console.log("registMaps: 新規マーカーを作成します");

      // 新規作成でダミーファイルの場合はエラー - 処理中止
      if (isDummyFile) {
        console.error("registMaps: 新規作成にはファイルが必要です");
        return false;
      }

      // 1. Firestoreドキュメント作成
      const docRef = await addDoc(mapsRef, {
        name: data.name,
        title: data.title,
        lat: data.lat,
        lng: data.lng,
        explanation: data.explanation,
        stampedUids: [],
        checkedInUids: [],
        createUserId: loginUserInfo?.uid || "",
        createTimeStamp: serverTimestamp(),
        updateTimeStamp: serverTimestamp(),
      });

      console.log(`registMaps: ドキュメント作成完了 - ID: ${docRef.id}`);

      // 2. ファイルアップロード処理
      if (isRealFile) {
        try {
          console.log(
            `registMaps: ファイルアップロード開始 - パス: ${docRef.id}/${data.name}`
          );
          const storageRef = ref(storage, `${docRef.id}/${data.name}`);

          // アップロード実行
          const uploadResult = await uploadBytes(storageRef, file);
          console.log(
            "registMaps: アップロード完了:",
            uploadResult.metadata.name
          );

          // アップロード成功後、URL取得を試みる（確認用）
          try {
            const downloadUrl = await getDownloadURL(storageRef);
            console.log(`registMaps: 新規アップロードのURL確認成功`);
          } catch (urlError) {
            console.warn("registMaps: URL取得エラー（無視可能）:", urlError);
          }
        } catch (uploadError) {
          console.error("registMaps: ファイルアップロードエラー:", uploadError);
          // エラーが発生しても成功とみなす（テキスト情報は保存済み）
        }
      }

      success = true;
    } else if (oldData) {
      // 【更新処理】
      console.log(`registMaps: マーカーID ${oldData.id} の更新を開始`);

      // 1. 変更があるかどうか確認
      const isTitleChanged = data.title !== oldData.title;
      const isExplanationChanged = data.explanation !== oldData.explanation;
      const isNameChanged = data.name !== oldData.name;
      const hasAnyChanges =
        isTitleChanged || isExplanationChanged || isNameChanged;

      console.log(
        `registMaps: 変更検出 - タイトル: ${isTitleChanged}, 説明: ${isExplanationChanged}, 名前: ${isNameChanged}, 実ファイル: ${isRealFile}, ダミーファイル: ${isDummyFile}`
      );

      // 変更がなく、ダミーファイルの場合は更新スキップ
      if (!hasAnyChanges && isDummyFile) {
        console.log(
          "registMaps: 変更なしのため、Firestore更新をスキップします"
        );

        // 変更がなくても成功として処理
        success = true;

        // 更新スキップの場合、既存のデータを維持したままクラスター再構築
        try {
          await getClusters(dispatch, mapsData, null, loginUserInfo);
          console.log(
            "registMaps: 変更なし - 既存データでクラスター再構築完了"
          );

          // 表示のみリフレッシュ（データ更新なし）
          dispatch({
            type: "REFRESH_MAP_VIEW",
            payload: {
              timestamp: new Date().getTime(),
            },
          });
          console.log("registMaps: 変更なし - 表示のみ更新完了");

          return true; // 早期リターン
        } catch (refreshError) {
          console.error("registMaps: 変更なし - 表示更新エラー:", refreshError);
          // エラーが発生しても処理続行
        }
      }

      // 2. Firestoreドキュメント参照取得
      const mapRef = doc(db, "maps", oldData.id);

      // 3. 変更がある場合のみFirestoreを更新
      if (hasAnyChanges) {
        try {
          // 更新するフィールドを定義
          const updateFields: any = {
            updateTimeStamp: serverTimestamp(),
          };

          // 変更があるフィールドのみを更新対象に含める
          if (isTitleChanged) {
            updateFields.title = data.title;
            console.log("registMaps: タイトルを更新します");
          }

          if (isExplanationChanged) {
            updateFields.explanation = data.explanation;
            console.log("registMaps: 説明を更新します");
          }

          // 名前変更は常に許可（名前はFirestoreのフィールドとして常に更新可能）
          if (isNameChanged) {
            updateFields.name = data.name;
            console.log("registMaps: 聖地名を更新します");
          }

          // Firestoreドキュメント更新実行
          await updateDoc(mapRef, updateFields);
          console.log("registMaps: ドキュメント更新完了");
        } catch (updateError) {
          console.error("registMaps: ドキュメント更新エラー:", updateError);
          return false;
        }
      } else {
        console.log("registMaps: ドキュメント更新スキップ（変更なし）");
      }

      // 4. ファイル処理分岐
      // 実ファイルがある場合か、名前が変更されている場合のみストレージ操作
      if (isRealFile || (isNameChanged && !isDummyFile)) {
        try {
          // 実ファイルがアップロードされる場合（新しい画像の追加/変更）
          if (isRealFile) {
            console.log("registMaps: 新しいファイルのアップロード処理");

            // 古いファイルの削除を試みる
            if (oldData.name) {
              try {
                const oldStorageRef = ref(
                  storage,
                  `${oldData.id}/${oldData.name}`
                );
                await deleteObject(oldStorageRef);
                console.log(
                  `registMaps: 古いファイル削除成功: ${oldData.id}/${oldData.name}`
                );
              } catch (deleteError) {
                console.warn(
                  "registMaps: 古いファイル削除エラー（処理継続）:",
                  deleteError
                );
              }
            }

            // 新しいファイルをアップロード
            const newStorageRef = ref(storage, `${oldData.id}/${data.name}`);
            const uploadResult = await uploadBytes(newStorageRef, file);
            console.log(
              `registMaps: 新ファイルアップロード完了:`,
              uploadResult.metadata.name
            );

            // 確認のためURLを取得し、データに保存
            try {
              const newUrl = await getDownloadURL(newStorageRef);
              console.log(`registMaps: 新しいファイルURL確認成功: ${newUrl}`);

              // 取得したURLをキャッシュ
              if (newUrl) {
                // キャッシュバスター付きURLを生成
                const timestamp = new Date().getTime();
                const randomSuffix = Math.random().toString(36).substring(7);
                const urlWithCache = `${newUrl}?t=${timestamp}&r=${randomSuffix}`;

                // 更新中のデータにURLを保存（クラスター生成時に使われる）
                data.url = urlWithCache;
                data.urlSource = newUrl;

                console.log(`registMaps: 新URLをデータに保存: ${data.id}`);
              }
            } catch (urlError) {
              console.warn("registMaps: URL確認エラー（無視可能）:", urlError);
            }
          }
          // 名前のみ変更されたケース（ファイル名変更）- ダミーファイルでない場合のみ
          else if (!isDummyFile && isNameChanged) {
            console.log(
              "registMaps: 名前のみ変更の処理 - ファイル名の変更が必要"
            );

            try {
              // 古いファイルの参照
              const oldStorageRef = ref(
                storage,
                `${oldData.id}/${oldData.name}`
              );

              // 新しいファイル名の参照
              const newStorageRef = ref(storage, `${oldData.id}/${data.name}`);

              // まず、古いファイルが存在するか確認
              let fileExists = false;
              try {
                await getDownloadURL(oldStorageRef);
                fileExists = true;
              } catch (existsError) {
                console.warn(
                  "registMaps: 元ファイルが存在しません",
                  existsError
                );
                // ファイルが存在しない場合は、この処理をスキップ
                return true;
              }

              // ファイルが存在する場合のみ処理を続行
              if (fileExists) {
                // 元のファイルのURLを取得してダウンロード
                try {
                  const originalUrl = await getDownloadURL(oldStorageRef);
                  console.log(
                    `registMaps: 元ファイルURL取得成功: ${originalUrl}`
                  );

                  // ファイルをダウンロード
                  console.log("registMaps: 元ファイルをダウンロード中...");
                  const response = await fetch(originalUrl);

                  if (!response.ok) {
                    throw new Error(
                      `ファイルダウンロードエラー: ${response.status}`
                    );
                  }

                  // ファイルデータをBlobに変換
                  const fileBlob = await response.blob();
                  console.log(
                    `registMaps: ファイルダウンロード成功: サイズ=${fileBlob.size}bytes`
                  );

                  if (fileBlob.size === 0) {
                    throw new Error("ダウンロードされたファイルサイズが0です");
                  }

                  // 新しい名前でファイルを再アップロード
                  console.log(
                    `registMaps: 名前変更のため再アップロード: ${data.name}`
                  );
                  const uploadResult = await uploadBytes(
                    newStorageRef,
                    fileBlob
                  );
                  console.log(
                    `registMaps: 名前変更アップロード完了: ${uploadResult.metadata.name}`
                  );

                  // 古いファイルを削除
                  try {
                    await deleteObject(oldStorageRef);
                    console.log(
                      `registMaps: 古いファイル削除成功: ${oldData.name}`
                    );
                  } catch (deleteError) {
                    console.warn(
                      "registMaps: 古いファイル削除エラー（処理継続）:",
                      deleteError
                    );
                  }

                  // 新しいファイルのURLを確認
                  try {
                    const newUrl = await getDownloadURL(newStorageRef);
                    console.log(
                      `registMaps: 新しいファイルURL確認成功: ${newUrl}`
                    );

                    // 取得したURLをキャッシュ
                    if (newUrl) {
                      // キャッシュバスター付きURLを生成
                      const timestamp = new Date().getTime();
                      const randomSuffix = Math.random()
                        .toString(36)
                        .substring(7);
                      const urlWithCache = `${newUrl}?t=${timestamp}&r=${randomSuffix}`;

                      // 更新中のデータにURLを保存（クラスター生成時に使われる）
                      data.url = urlWithCache;
                      data.urlSource = newUrl;

                      console.log(
                        `registMaps: 名前変更後の新URLをデータに保存: ${data.id}`
                      );
                    }
                  } catch (urlError) {
                    console.warn(
                      "registMaps: URL確認エラー（無視可能）:",
                      urlError
                    );
                  }
                } catch (fileError) {
                  console.error(
                    "registMaps: ファイル名変更処理中にエラーが発生しました:",
                    fileError
                  );
                  return false; // 処理失敗を返す
                }
              }
            } catch (nameChangeError) {
              console.error(
                "registMaps: ファイル名変更処理エラー:",
                nameChangeError
              );
              return false; // 処理失敗を返す
            }
          }
        } catch (fileError) {
          console.error("registMaps: ファイル処理全体エラー:", fileError);
          // ファイル処理に失敗しても、データ更新は成功しているため、成功扱いとする
        }
      } else {
        // ダミーファイルでテキスト変更のみの場合
        if (isDummyFile && hasAnyChanges) {
          console.log("registMaps: テキスト情報のみ更新 - 画像処理はスキップ");

          // 聖地名が変更された場合のログ（確認用）
          if (isNameChanged) {
            console.log(
              "registMaps: 聖地名変更を検出 - ファイル名の変更が必要"
            );

            // 古い名前を保存（後で画像取得時に使用）
            data.oldName = oldData.name;
            console.log(
              `registMaps: 古い聖地名を参照用に保存: ${oldData.name}`
            );

            // ダミーファイルでも名前変更がある場合は、Storage上のファイル名も変更する
            try {
              // 古いファイルの参照
              const oldStorageRef = ref(
                storage,
                `${oldData.id}/${oldData.name}`
              );
              // 新しいファイル名の参照
              const newStorageRef = ref(storage, `${oldData.id}/${data.name}`);

              // 古いファイルが存在するか確認
              try {
                const originalUrl = await getDownloadURL(oldStorageRef);
                console.log(
                  `registMaps: 元ファイルが存在することを確認: ${originalUrl}`
                );

                // ファイルをダウンロード
                console.log(
                  "registMaps: ダミーファイル使用時の名前変更 - 元ファイルをダウンロード中..."
                );
                const response = await fetch(originalUrl);

                if (!response.ok) {
                  throw new Error(
                    `ファイルダウンロードエラー: ${response.status}`
                  );
                }

                // ファイルデータをBlobに変換
                const fileBlob = await response.blob();
                console.log(
                  `registMaps: ファイルダウンロード成功: サイズ=${fileBlob.size}bytes`
                );

                if (fileBlob.size === 0) {
                  throw new Error("ダウンロードされたファイルサイズが0です");
                }

                // 新しい名前でファイルを再アップロード
                console.log(
                  `registMaps: ダミーファイル使用時の名前変更 - 再アップロード: ${data.name}`
                );
                const uploadResult = await uploadBytes(newStorageRef, fileBlob);
                console.log(
                  `registMaps: 名前変更アップロード完了: ${uploadResult.metadata.name}`
                );

                // 古いファイルを削除
                try {
                  await deleteObject(oldStorageRef);
                  console.log(
                    `registMaps: 古いファイル削除成功: ${oldData.name}`
                  );
                } catch (deleteError) {
                  console.warn(
                    "registMaps: 古いファイル削除エラー（処理継続）:",
                    deleteError
                  );
                }

                // 新しいURLを取得して保存
                try {
                  const newUrl = await getDownloadURL(newStorageRef);
                  console.log(
                    `registMaps: 新しいファイルURL確認成功: ${newUrl}`
                  );

                  // キャッシュバスター付きURLを生成
                  const timestamp = new Date().getTime();
                  const randomSuffix = Math.random().toString(36).substring(7);
                  const urlWithCache = `${newUrl}?t=${timestamp}&r=${randomSuffix}`;

                  // 更新中のデータにURLを保存
                  data.url = urlWithCache;
                  data.urlSource = newUrl;

                  console.log(
                    `registMaps: ダミーファイル使用時の名前変更 - 新URLを保存: ${data.id}`
                  );
                } catch (urlError) {
                  console.warn(
                    "registMaps: URL確認エラー（無視可能）:",
                    urlError
                  );
                }
              } catch (downloadError) {
                console.warn(
                  "registMaps: 元ファイルのダウンロードに失敗:",
                  downloadError
                );
                console.log(
                  "registMaps: ファイルが存在しないか、アクセスできません"
                );
              }
            } catch (nameChangeError) {
              console.error(
                "registMaps: ダミーファイル使用時の名前変更処理エラー:",
                nameChangeError
              );
              // エラーが発生しても処理は続行（Firestoreの更新は成功しているため）
            }
          }
        } else {
          console.log("registMaps: 変更なし - 画像処理は不要");
        }
      }

      success = true;
    }

    // 処理成功時のリフレッシュ処理
    if (success) {
      console.log("registMaps: 処理成功 - データ再読み込み準備");

      // メモリリーク防止: 古いBlobURLの解放
      try {
        let releasedCount = 0;
        mapsData.forEach((map) => {
          if (map.url && map.url.startsWith("blob:")) {
            URL.revokeObjectURL(map.url);
            releasedCount++;
          }
        });
        if (releasedCount > 0) {
          console.log(
            `registMaps: ${releasedCount}件のBlobURL参照を解放しました`
          );
        }
      } catch (revokeError) {
        console.warn("registMaps: URL解放エラー:", revokeError);
      }

      // Firebase反映を待機（重要: 十分な待機時間を確保）
      console.log("registMaps: Firebase反映待機...");
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        // 既存のデータのURLを保存
        const existingUrlMap = new Map<string, string>();

        // 画像URLの優先順位:
        // 1. 今回のアップロードで取得したURL (data.url)
        // 2. 既存のマップデータに保存されているURL (mapsData)

        // まず、今回更新したデータのURLを保存（最も新しいため優先）
        if (data && data.id && data.url && !data.url.includes("placeholder")) {
          existingUrlMap.set(data.id, data.url);
          console.log(
            `registMaps: 今回更新したURLを保存: ID=${data.id}, URL=${data.url}`
          );
        }

        // 次に、既存のマップデータからURLを保存（今回更新したものを除く）
        mapsData.forEach((map) => {
          // 今回更新したデータでなければ、既存のURLを保存
          if (
            map.id &&
            map.url &&
            !map.url.includes("placeholder") &&
            (!data || map.id !== data.id || !existingUrlMap.has(map.id))
          ) {
            existingUrlMap.set(map.id, map.url);
            console.log(`registMaps: 既存URL保存: ID=${map.id}`);
          }
        });

        // デバッグのためにURLの保存状況を出力
        console.log(
          `registMaps: URLキャッシュマップに${existingUrlMap.size}件のURLを保存しました`
        );

        // 全データを強制的に再読み込み
        console.log("registMaps: 全データの再読み込み開始");

        // マップデータを再取得
        const mapsRef = collection(db, "maps");
        const mapsSnap = await getDocs(mapsRef);
        const freshMapsData: MapData[] = [];

        console.log(
          `registMaps: Firebase から ${mapsSnap.size}件のデータを取得`
        );

        mapsSnap.forEach((map) => {
          if (map.exists()) {
            const mapData = { ...map.data(), id: map.id } as MapData;

            // 既存のURLがあれば保持
            if (existingUrlMap.has(mapData.id)) {
              mapData.url = existingUrlMap.get(mapData.id) || mapData.url;
            }

            freshMapsData.push(mapData);
          }
        });

        console.log(`registMaps: 処理済みデータ ${freshMapsData.length}件`);

        // ストアに反映
        dispatch(listMapsAction(freshMapsData));
        console.log("registMaps: 新データをストアに反映");

        // ログインユーザー依存のデータ更新
        if (loginUserInfo && loginUserInfo.uid) {
          console.log(
            `registMaps: ログインユーザー(${loginUserInfo.uid})用のクラスター再構築`
          );

          // 最新データでクラスターを再構築
          await getClusters(dispatch, freshMapsData, null, loginUserInfo);
          console.log("registMaps: クラスター再構築完了");
        }
      } catch (refreshError) {
        console.error("registMaps: データ再読み込みエラー:", refreshError);
      }
    }

    return success;
  } catch (e) {
    console.error("registMaps 致命的エラー:", e);
    return false;
  }
};

// Firebase Storageにファイルが存在するかチェックする補助関数
async function fileExistsInStorage(
  storage: any,
  path: string
): Promise<boolean> {
  try {
    const fileRef = ref(storage, path);
    await getDownloadURL(fileRef);
    return true;
  } catch (error: any) {
    if (error.code === "storage/object-not-found") {
      return false;
    }
    console.warn(`ファイル存在確認エラー (${path}):`, error);
    return false;
  }
}

export const setSearchPlace = (
  dispatch: Dispatch,
  searchPlace: string
): void => {
  try {
    dispatch(setSearchPlaceAction(searchPlace));
  } catch (e) {
    console.dir(e);
  }
};

export const setCurrentLocation = (
  dispatch: Dispatch,
  currentLocation: { lat: number; lng: number }
): void => {
  try {
    dispatch(setCurrentLocationAction(currentLocation));
  } catch (e) {
    console.dir(e);
  }
};

export const setStamp = async (
  dispatch: Dispatch,
  map: MapData,
  mapsData: MapData[],
  loginUserInfo: UserData | null
): Promise<boolean> => {
  try {
    if (!loginUserInfo || !loginUserInfo.uid) return false;

    const mapRef = doc(db, "maps", map.id);
    const mapDoc = await getDoc(mapRef);

    if (!mapDoc.exists()) return false;

    const mapData = mapDoc.data();
    const stampedUids = mapData.stampedUids || [];

    // 既にスタンプがある場合は何もしない
    if (stampedUids.includes(loginUserInfo.uid)) return false;

    // スタンプを追加
    stampedUids.push(loginUserInfo.uid);
    await updateDoc(mapRef, {
      stampedUids: stampedUids,
      stampedTimeStamp: serverTimestamp(),
    });

    // 更新したデータで表示を更新
    const newMapsData = mapsData.map((m) => {
      if (m.id === map.id) {
        return {
          ...m,
          stampedUids: stampedUids,
          isStamped: true,
        };
      }
      return m;
    });

    dispatch(setStampAction(newMapsData));
    await getClusters(dispatch, newMapsData, map.id, loginUserInfo);

    return true;
  } catch (e) {
    console.dir(e);
    return false;
  }
};

export const deleteMap = async (
  dispatch: Dispatch,
  map: MapData
): Promise<boolean> => {
  try {
    console.log(
      `deleteMap: マーカー削除処理開始 - ID: ${map.id}, 名前: ${map.name}`
    );
    const storage = getStorage();
    const mapRef = doc(db, "maps", map.id);
    let firestoreSuccess = false;
    let storageSuccess = false;

    // Firestoreからドキュメントを削除
    try {
      await deleteDoc(mapRef);
      firestoreSuccess = true;
      console.log(`deleteMap: Firestoreドキュメント削除成功 - ID: ${map.id}`);
    } catch (firestoreError) {
      console.error(
        `deleteMap: Firestoreドキュメント削除エラー:`,
        firestoreError
      );
      return false; // Firestoreの削除は必須なので、失敗時は処理中止
    }

    // Storageから画像を削除（複数パターンで試行）
    try {
      // 現在の名前でStorageから削除を試行
      const storageRef = ref(storage, `${map.id}/${map.name}`);
      try {
        await deleteObject(storageRef);
        storageSuccess = true;
        console.log(
          `deleteMap: 現在の名前で画像削除成功 - ${map.id}/${map.name}`
        );
      } catch (currentNameError) {
        console.warn(
          `deleteMap: 現在の名前での画像削除に失敗: ${map.id}/${map.name}`
        );

        // 古い名前がある場合は、その名前での削除も試行
        if (map.oldName && map.oldName !== map.name) {
          const oldStorageRef = ref(storage, `${map.id}/${map.oldName}`);
          try {
            await deleteObject(oldStorageRef);
            storageSuccess = true;
            console.log(
              `deleteMap: 古い名前で画像削除成功 - ${map.id}/${map.oldName}`
            );
          } catch (oldNameError) {
            console.warn(
              `deleteMap: 古い名前での画像削除にも失敗: ${map.id}/${map.oldName}`
            );
          }
        }
      }
    } catch (storageError) {
      console.warn(`deleteMap: 画像削除中にエラー発生:`, storageError);
      // Storage操作失敗は許容（Firestoreが成功していれば）
    }

    // データを更新して再表示
    console.log(`deleteMap: 削除後のデータ再読み込み`);
    await listMaps(dispatch);

    // Firestoreの削除が成功していれば全体として成功とみなす
    return firestoreSuccess;
  } catch (e) {
    console.error(`deleteMap: 予期しないエラー:`, e);
    return false;
  }
};

export const checkAuth = async (dispatch: Dispatch): Promise<void> => {
  try {
    console.log("checkAuth開始");
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      console.log(
        "認証状態変更検出:",
        user ? `ユーザー: ${user.uid}` : "未ログイン"
      );

      if (user) {
        // ユーザーがログインしている場合
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();

          console.log("ユーザー情報:", userData);

          dispatch(
            setloginStatusAction(LOGIN_STATUS.login, {
              displayName: userData.displayName,
              uid: userData.uid,
              id: userDoc.id,
              photoURL: user.photoURL || undefined,
              isAdmin: userData.isAdmin || false,
            })
          );
        } else {
          console.log("該当するユーザー情報が見つかりませんでした");
        }
      } else {
        // ユーザーがログアウトしている場合
        console.log("ログアウト状態を設定");
        dispatch(setloginStatusAction(LOGIN_STATUS.logout, null));
      }
    });

    console.log("checkAuth: 監視設定完了");
  } catch (e) {
    console.error("checkAuth エラー:", e);
  }
};

export const checkInLocation = async (
  dispatch: Dispatch,
  map: MapData,
  mapsData: MapData[],
  loginUserInfo: UserData | null
): Promise<boolean> => {
  try {
    if (!loginUserInfo || !loginUserInfo.uid) return false;

    const mapRef = doc(db, "maps", map.id);
    const mapDoc = await getDoc(mapRef);

    if (!mapDoc.exists()) return false;

    const mapData = mapDoc.data();
    const checkedInUids = mapData.checkedInUids || [];

    // 既にチェックインしている場合は何もしない
    if (checkedInUids.includes(loginUserInfo.uid)) return false;

    // チェックインを追加
    checkedInUids.push(loginUserInfo.uid);
    await updateDoc(mapRef, {
      checkedInUids: checkedInUids,
      checkedInTimeStamp: serverTimestamp(),
    });

    // 更新したデータで表示を更新
    const newMapsData = mapsData.map((m) => {
      if (m.id === map.id) {
        return {
          ...m,
          checkedInUids: checkedInUids,
          isCheckedIn: true,
        };
      }
      return m;
    });

    dispatch(setCheckInAction(newMapsData));
    await getClusters(dispatch, newMapsData, null, loginUserInfo);

    return true;
  } catch (e) {
    console.dir(e);
    return false;
  }
};

const setSearchPlaceAction = (searchPlace: string) => {
  return {
    type: "SET_SEARCH_PLACE",
    payload: {
      searchPlace: searchPlace,
    },
  };
};

const setCurrentLocationAction = (currentLocation: {
  lat: number;
  lng: number;
}) => {
  return {
    type: "SET_CURRENT_LOCATION",
    payload: {
      currentLocation: currentLocation,
    },
  };
};

const setStampAction = (mapsData: MapData[]) => {
  return {
    type: "SET_STAMP",
    payload: {
      mapsData: mapsData,
    },
  };
};
