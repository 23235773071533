import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAuth,
  signInWithPopup,
  TwitterAuthProvider,
  GoogleAuthProvider,
  signOut,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  AuthProvider,
  sendPasswordResetEmail,
  EmailAuthProvider,
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { LOGIN_STATUS } from "../state/ducks/map/actions";

// 認証状態アクション定数
export const AUTH_SET_STATUS = "SET_LOGIN_STATUS";
export const AUTH_SET_USER = "SET_LOGIN_USER_INFO";
export const AUTH_LOGOUT = "auth/LOGOUT";

// 認証プロバイダー定数
export const AUTH_PROVIDERS = {
  TWITTER: "twitter",
  GOOGLE: "google",
  EMAIL: "email",
};

// Firebaseの設定
const firebaseConfig = {
  apiKey: "AIzaSyCC62Bvln71F53GQQD6qw517IlqZTMMH4E",
  authDomain: "stamprallyapp.firebaseapp.com",
  projectId: "stamprallyapp",
  storageBucket: "stamprallyapp.appspot.com",
  messagingSenderId: "111139760629",
  appId: "1:111139760629:web:2049c04419a4847b1898bc",
};

// Firebaseの初期化
let firebaseApp;
try {
  firebaseApp = initializeApp(firebaseConfig);
} catch (error) {
  console.error("Firebase初期化エラー:", error);
}

// 認証プロバイダー
const twitterProvider = new TwitterAuthProvider();
const googleProvider = new GoogleAuthProvider();

/**
 * Firebase認証を使用するカスタムフック
 */
export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const dispatch = useDispatch();
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);

  // ユーザー情報を取得する
  const fetchUserRole = useCallback(
    async (uid: string, displayName: string | null) => {
      try {
        console.log(
          `ユーザー情報取得開始: uid=${uid}, displayName=${displayName}`
        );
        const userRef = doc(db, "users", uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("既存ユーザーデータ:", userData);

          // 既存ユーザーでもdisplayNameが更新されている場合は更新
          if (
            displayName &&
            (!userData.displayName || userData.displayName !== displayName)
          ) {
            await setDoc(
              userRef,
              {
                displayName,
                updatedAt: new Date(),
              },
              { merge: true }
            );
            console.log(`既存ユーザーのdisplayNameを更新: ${displayName}`);
          }

          setIsAdmin(userData.isAdmin === true);
          console.log(
            `管理者権限: ${userData.isAdmin === true ? "あり" : "なし"}`
          );
          return userData.isAdmin === true;
        } else {
          // ユーザードキュメントが存在しない場合は作成
          console.log("新規ユーザー登録:", { uid, displayName });
          await setDoc(userRef, {
            uid,
            displayName: displayName || `User ${uid.substring(0, 5)}`,
            isAdmin: false,
            createdAt: new Date(),
          });
          return false;
        }
      } catch (err) {
        console.error("ユーザーロール取得エラー:", err);
        return false;
      }
    },
    [db]
  );

  // 認証状態変更のリスナー
  useEffect(() => {
    console.log("認証状態リスナーを設定");
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setIsLoading(true);
      try {
        if (firebaseUser) {
          console.log(
            "認証済みユーザー:",
            firebaseUser.uid,
            "表示名:",
            firebaseUser.displayName,
            "メール:",
            firebaseUser.email
          );
          setUser(firebaseUser);
          setIsLoggedIn(true);

          // ユーザーの権限を確認
          const admin = await fetchUserRole(
            firebaseUser.uid,
            firebaseUser.displayName
          );
          console.log("管理者権限の確認結果:", admin);

          // Reduxに認証状態を通知
          dispatch({
            type: LOGIN_STATUS,
            payload: {
              loginStatus: "LOGIN",
              loginUserInfo: {
                id: firebaseUser.uid,
                uid: firebaseUser.uid,
                isAdmin: admin,
                displayName: firebaseUser.displayName || firebaseUser.email,
                email: firebaseUser.email,
              },
            },
          });

          // SET_LOGIN_USER_INFOも使用されているが、LOGIN_STATUSには定義されていないので
          // 直接文字列で指定
          dispatch({
            type: AUTH_SET_USER,
            payload: {
              loginUserInfo: {
                id: firebaseUser.uid,
                uid: firebaseUser.uid,
                isAdmin: admin,
                displayName: firebaseUser.displayName || firebaseUser.email,
                email: firebaseUser.email,
              },
            },
          });
        } else {
          console.log("未認証状態");
          setUser(null);
          setIsAdmin(false);
          setIsLoggedIn(false);

          // Reduxにログアウト状態を通知
          dispatch({
            type: LOGIN_STATUS,
            payload: {
              loginStatus: "LOGOUT",
              loginUserInfo: {
                id: "",
                uid: "",
                isAdmin: false,
              },
            },
          });

          // ログアウト時にもユーザー情報をリセット
          dispatch({
            type: AUTH_SET_USER,
            payload: {
              loginUserInfo: {
                id: "",
                uid: "",
                isAdmin: false,
              },
            },
          });
        }
      } catch (err) {
        console.error("認証状態処理エラー:", err);
        setError(err instanceof Error ? err : new Error("不明な認証エラー"));
      } finally {
        setIsLoading(false);
        setIsInitialized(true);
      }
    });

    // クリーンアップ関数
    return () => unsubscribe();
  }, [auth, dispatch, fetchUserRole]);

  // プロバイダーによるログイン汎用関数
  const loginWithProvider = useCallback(
    async (provider: AuthProvider) => {
      console.log(`${provider.providerId}でログイン開始`);
      setIsLoading(true);
      setError(null);

      try {
        const result = await signInWithPopup(auth, provider);
        console.log(`${provider.providerId}ログイン成功:`, result.user.uid);

        // ユーザー権限を取得
        const admin = await fetchUserRole(
          result.user.uid,
          result.user.displayName
        );

        // 直接Reduxステートを更新（onAuthStateChangedを補完）
        dispatch({
          type: LOGIN_STATUS,
          payload: {
            loginStatus: "LOGIN",
            loginUserInfo: {
              id: result.user.uid,
              uid: result.user.uid,
              isAdmin: admin,
              displayName: result.user.displayName || result.user.email,
              email: result.user.email,
            },
          },
        });

        // ユーザー情報も直接更新
        dispatch({
          type: AUTH_SET_USER,
          payload: {
            loginUserInfo: {
              id: result.user.uid,
              uid: result.user.uid,
              isAdmin: admin,
              displayName: result.user.displayName || result.user.email,
              email: result.user.email,
            },
          },
        });

        return true;
      } catch (err) {
        console.error(`${provider.providerId}ログインエラー:`, err);

        // エラーメッセージをチェックして登録済みのユーザーかどうか確認
        const errorMessage =
          err instanceof Error ? err.message : "不明なエラー";

        if (
          errorMessage.includes("auth/email-already-in-use") ||
          errorMessage.includes("already exists") ||
          errorMessage.includes("already in use")
        ) {
          console.log(
            "既に登録されているユーザーです。ログインに切り替えます。"
          );
          // ログイン処理に切り替え
          try {
            // 既存のログイン処理を呼び出し
            const loginResult = await signInWithPopup(auth, provider);
            console.log(
              "既存ユーザーとしてログイン成功:",
              loginResult.user.uid
            );
            return true;
          } catch (loginErr) {
            console.error("既存ユーザーログイン失敗:", loginErr);
            setError(
              loginErr instanceof Error
                ? new Error(
                    `既存ユーザーとしてのログインに失敗しました: ${loginErr.message}`
                  )
                : new Error("既存ユーザーとしてのログインに失敗しました")
            );
            return false;
          }
        }

        setError(
          err instanceof Error
            ? err
            : new Error("ログイン中にエラーが発生しました")
        );
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [auth, dispatch, fetchUserRole]
  );

  // Twitterでログイン
  const loginWithTwitter = useCallback(async () => {
    return loginWithProvider(twitterProvider);
  }, [loginWithProvider]);

  // Googleでログイン
  const loginWithGoogle = useCallback(async () => {
    return loginWithProvider(googleProvider);
  }, [loginWithProvider]);

  // メールアドレスでログイン/登録
  const loginWithEmail = useCallback(
    async (email: string, password: string, isSignUp = false) => {
      console.log(
        `メールアドレスで${isSignUp ? "登録" : "ログイン"}開始:`,
        email
      );
      setIsLoading(true);
      setError(null);

      try {
        // 新規登録かログインかで処理を分ける
        const result = isSignUp
          ? await createUserWithEmailAndPassword(auth, email, password)
          : await signInWithEmailAndPassword(auth, email, password);

        console.log(
          `メールアドレスで${isSignUp ? "登録" : "ログイン"}成功:`,
          result.user.uid
        );

        // ユーザー権限を取得
        const admin = await fetchUserRole(
          result.user.uid,
          result.user.displayName || email.split("@")[0]
        );

        // Reduxに認証状態を通知
        dispatch({
          type: LOGIN_STATUS,
          payload: {
            loginStatus: "LOGIN",
            loginUserInfo: {
              id: result.user.uid,
              uid: result.user.uid,
              isAdmin: admin,
              displayName: result.user.displayName || email.split("@")[0],
              email: result.user.email,
            },
          },
        });

        // ユーザー情報も直接更新
        dispatch({
          type: AUTH_SET_USER,
          payload: {
            loginUserInfo: {
              id: result.user.uid,
              uid: result.user.uid,
              isAdmin: admin,
              displayName: result.user.displayName || email.split("@")[0],
              email: result.user.email,
            },
          },
        });

        return true;
      } catch (err) {
        console.error(
          `メールアドレス${isSignUp ? "登録" : "ログイン"}エラー:`,
          err
        );

        // 既に登録済みの場合はログインを試みる
        const errorMessage =
          err instanceof Error ? err.message : "不明なエラー";

        if (isSignUp && errorMessage.includes("auth/email-already-in-use")) {
          console.log(
            "既に登録されているメールアドレスです。ログインに切り替えます。"
          );
          try {
            return await loginWithEmail(email, password, false); // ログインモードで再試行
          } catch (loginErr) {
            console.error("既存ユーザーログイン失敗:", loginErr);
            setError(
              loginErr instanceof Error
                ? new Error(
                    `既存ユーザーとしてのログインに失敗しました: ${loginErr.message}`
                  )
                : new Error("既存ユーザーとしてのログインに失敗しました")
            );
            return false;
          }
        }

        setError(
          err instanceof Error
            ? err
            : new Error(
                `メールアドレス${
                  isSignUp ? "登録" : "ログイン"
                }中にエラーが発生しました`
              )
        );
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [auth, dispatch, fetchUserRole]
  );

  // パスワードリセット機能
  const resetPassword = useCallback(
    async (email: string) => {
      console.log("パスワードリセットメール送信開始:", email);
      setIsLoading(true);
      setError(null);

      try {
        await sendPasswordResetEmail(auth, email);
        console.log("パスワードリセットメール送信成功");
        return true;
      } catch (err) {
        console.error("パスワードリセットエラー:", err);
        setError(
          err instanceof Error
            ? err
            : new Error("パスワードリセットメール送信中にエラーが発生しました")
        );
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [auth]
  );

  // ログアウト
  const logout = useCallback(async () => {
    console.log("ログアウト開始");
    setIsLoading(true);
    setError(null);

    try {
      await signOut(auth);
      console.log("ログアウト成功");

      // 直接Reduxステートを更新（onAuthStateChangedを補完）
      dispatch({
        type: LOGIN_STATUS,
        payload: {
          loginStatus: "LOGOUT",
          loginUserInfo: {
            id: "",
            uid: "",
            isAdmin: false,
          },
        },
      });

      // ユーザー情報も直接更新
      dispatch({
        type: AUTH_SET_USER,
        payload: {
          loginUserInfo: {
            id: "",
            uid: "",
            isAdmin: false,
          },
        },
      });

      return true;
    } catch (err) {
      console.error("ログアウトエラー:", err);
      setError(
        err instanceof Error
          ? err
          : new Error("ログアウト中にエラーが発生しました")
      );
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [auth, dispatch]);

  // 認証状態を確認
  const checkAuthState = useCallback(async () => {
    console.log("認証状態確認");
    if (user) {
      console.log("認証済みユーザー:", user.uid);
      return {
        isLoggedIn: true,
        user: {
          id: user.uid,
          uid: user.uid,
          isAdmin,
          displayName: user.displayName || user.email?.split("@")[0],
          email: user.email,
        },
      };
    } else {
      console.log("未認証状態");
      return {
        isLoggedIn: false,
        user: null,
      };
    }
  }, [user, isAdmin]);

  return {
    isLoggedIn,
    isInitialized,
    user,
    isAdmin,
    isLoading,
    error,
    loginWithTwitter,
    loginWithGoogle,
    loginWithEmail,
    resetPassword,
    logout,
    checkAuthState,
  };
};
