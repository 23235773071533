import React from "react";
import { Button, TextField } from "@material-ui/core";

interface SearchPlaceProps {
  style?: React.CSSProperties;
  isLoading?: boolean;
  label?: string;
  searchPlace: string;
  onChangePlace: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickGeocode: (event: React.MouseEvent, searchPlace: string) => void;
}

const SearchPlace: React.FC<SearchPlaceProps> = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          ...props.style,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <TextField
          size="small"
          disabled={props.isLoading}
          type="text"
          label={props.label}
          onChange={props.onChangePlace}
          value={props.searchPlace}
          style={{ flex: 1, marginRight: "8px" }}
          inputProps={{ style: { width: "100%" } }}
        />
        <Button
          size="small"
          disabled={props.isLoading}
          variant="text"
          onClick={(e) => props.onClickGeocode(e, props.searchPlace)}
        >
          検索
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SearchPlace;
