/**
 * アプリケーションのメインコンテナ
 * ルーティング設定とナビゲーション構造を定義
 * ハッシュルーティングを使用してSPA構造を実現
 */
import React from "react";
import "./App.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import Map from "./map";

const App: React.FC = () => {
  // アプリケーション初期化時にコンソールにデバッグ情報を表示
  console.log("[App] アプリケーションコンテナ初期化:", window.location.href);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Map} />
        <Route path="/map" component={Map} />
      </Switch>
      {/* <Link to='/'>Back To Top</Link> */}
    </HashRouter>
  );
};

export default App;
