import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { FaYoutube } from "react-icons/fa";
import { SiNiconico } from "react-icons/si";
import { PhotoDropDialogProps, FileWithPreview } from "./types";
import FileDropzone from "./FileDropzone";
import FormFields from "./FormFields";
import ActionButtons from "./ActionButtons";

/**
 * 画像アップロードと情報入力ダイアログコンポーネント
 * SOLID原則に基づいて分割された各コンポーネントを組み合わせて構成
 */
const PhotoDropDialog: React.FC<PhotoDropDialogProps> = ({
  open,
  editingMarker,
  onClose,
  onClickAddPhoto,
  onDelete,
  onCheckIn,
  isLoading,
  loginUserInfo,
  isAdmin,
  isEditing,
  isLogin,
  isCheckedIn,
  name: initialName = "",
  title: initialTitle = "",
  explanation: initialExplanation = "",
  imgSrc,
  isCreateUserAction,
}) => {
  // コンポーネントがマウントされたときにプロパティをログ出力（デバッグ用）
  console.log("PhotoDropDialog: 受け取ったプロパティ", {
    isAdmin,
    isCreateUserAction,
    loginUserInfo,
    isEditing,
    isLogin,
  });

  // 状態の管理
  const [name, setName] = useState<string>(initialName);
  const [title, setTitle] = useState<string>(initialTitle);
  const [explanation, setExplanation] = useState<string>(initialExplanation);
  const [file, setFile] = useState<FileWithPreview | null>(null);
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);

  // ダイアログが開かれたときに初期値を設定
  useEffect(() => {
    if (open) {
      setName(initialName);
      setTitle(initialTitle);
      setExplanation(initialExplanation);
      setFile(null);

      // 編集中のマーカーの作成者IDとログインユーザーIDが一致するか確認
      if (
        loginUserInfo &&
        editingMarker &&
        editingMarker.createUserId === loginUserInfo.uid
      ) {
        setIsCreateUser(true);
      } else if (isCreateUserAction) {
        // フラグが直接指定されている場合はそれを使用
        setIsCreateUser(true);
      } else {
        setIsCreateUser(false);
      }

      // デバッグログ
      console.log("PhotoDropDialog: 初期化", {
        initialName,
        initialTitle,
        initialExplanation,
        isCreateUserAction,
        isCreateUser:
          isCreateUserAction ||
          (loginUserInfo &&
            editingMarker &&
            editingMarker.createUserId === loginUserInfo.uid),
        editingMarker,
        loginUserInfo,
      });
    }
  }, [
    open,
    initialName,
    initialTitle,
    initialExplanation,
    editingMarker,
    loginUserInfo,
    isCreateUserAction,
  ]);

  // ファイルドロップ時のハンドラー
  const handleFileDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0] as FileWithPreview);
    }
  }, []);

  // 保存ボタン押下時のハンドラー
  const handleSave = useCallback(() => {
    // 実ファイルがアップロードされた場合
    if (file) {
      console.log("実ファイルで保存:", file.name);
      onClickAddPhoto(name, title, explanation, file);
    }
    // 編集モードでファイルがない場合（テキスト情報のみの更新）
    else if (editingMarker) {
      console.log("テキストのみ更新 - ダミーファイル使用");
      // ファイルがない場合はサイズ0のダミーファイルを作成（テキスト情報のみ更新の意味）
      const dummyFile = new File([""], "dummy", { type: "text/plain" });
      onClickAddPhoto(name, title, explanation, dummyFile);
    }
    // 新規作成モードでファイルがない場合
    else {
      console.log("新規作成でファイルなし - 処理中止");
      // ファイルなしでの新規作成は実行しない - 代わりにエラーメッセージなどを表示
      alert("画像ファイルを選択してください");
    }
  }, [name, title, explanation, file, onClickAddPhoto, editingMarker]);

  // 変更があるかどうかのチェック
  const hasChanges = useMemo(() => {
    // 新規作成の場合、ファイルがあれば変更あり
    if (!editingMarker) {
      return !!file;
    }

    // 編集モードの場合
    // ファイルがアップロードされた場合は変更あり
    if (file) {
      return true;
    }

    // テキスト情報の変更チェック
    const nameChanged = name !== initialName;
    const titleChanged = title !== initialTitle;
    const explanationChanged = explanation !== initialExplanation;

    return nameChanged || titleChanged || explanationChanged;
  }, [
    name,
    title,
    explanation,
    file,
    editingMarker,
    initialName,
    initialTitle,
    initialExplanation,
  ]);

  // Google Mapsリンク生成
  const generateGoogleMapLink = useCallback(() => {
    if (editingMarker && editingMarker.lat && editingMarker.lng) {
      return `https://www.google.com/maps/search/?api=1&query=${editingMarker.lat},${editingMarker.lng}`;
    }
    return "";
  }, [editingMarker]);

  // 編集可能かどうかの判定
  const isEditable = useMemo(() => {
    // 管理者は常に編集可能
    if (isAdmin) {
      console.log("管理者権限があるため編集可能");
      return true;
    }

    // 編集モードで、自分が作成したマーカーなら編集可能
    if (isEditing && isCreateUserAction) {
      console.log("自分が作成したマーカーのため編集可能");
      return true;
    }

    // それ以外は編集不可
    console.log("編集権限なし", {
      isEditing,
      isAdmin,
      isCreateUserAction,
      loginUserInfo: loginUserInfo || "なし",
      createUserId: editingMarker?.createUserId || "なし",
    });
    return false;
  }, [isEditing, isAdmin, isCreateUserAction, loginUserInfo, editingMarker]);

  // タイトルの決定
  const dialogTitle = useMemo(() => {
    let title = "詳細";

    if (isEditing) {
      title = isEditable ? "編集" : "詳細";
    } else {
      title = isAdmin ? "新規作成" : "閲覧";
    }

    console.log(`ダイアログタイトル: ${title}`, {
      isEditing,
      isEditable,
      isAdmin,
    });

    return title;
  }, [isEditing, isEditable, isAdmin]);

  // 位置情報があるかの判定（チェックイン用）
  const hasValidLocationData =
    isEditing && !!editingMarker?.lat && !!editingMarker?.lng;

  // 詳細表示モードか判定
  const isDetailView = isEditing && !isEditable;

  // YouTubeリンク生成
  const getYoutubeSearchLink = useCallback(() => {
    if (title) {
      return `https://www.youtube.com/results?search_query=${encodeURIComponent(
        title
      )}`;
    }
    return `https://www.youtube.com/results?search_query=${encodeURIComponent(
      name
    )}`;
  }, [name, title]);

  // ニコニコ動画リンク生成
  const getNiconicoSearchLink = useCallback(() => {
    if (title) {
      return `https://www.nicovideo.jp/search/${encodeURIComponent(title)}`;
    }
    return `https://www.nicovideo.jp/search/${encodeURIComponent(name)}`;
  }, [name, title]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>

      <DialogContent>
        {/* ファイルドロップゾーン */}
        {isEditing && imgSrc && !file ? (
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <img
              src={imgSrc}
              alt={title || "プレビュー"}
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          <FileDropzone
            onDrop={handleFileDrop}
            file={file}
            disabled={!isEditable}
          />
        )}

        {/* 詳細表示モードの場合にSNSアイコンを表示 */}
        {isDetailView && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={1}
          >
            <IconButton
              href={getYoutubeSearchLink()}
              target="_blank"
              rel="noopener noreferrer"
              title="YouTubeで検索"
              aria-label="YouTubeで検索"
              style={{ color: "#FF0000" }}
            >
              <FaYoutube size={28} />
            </IconButton>
            <IconButton
              href={getNiconicoSearchLink()}
              target="_blank"
              rel="noopener noreferrer"
              title="ニコニコ動画で検索"
              aria-label="ニコニコ動画で検索"
              style={{ color: "#252525" }}
            >
              <SiNiconico size={24} />
            </IconButton>
          </Box>
        )}

        {/* フォームフィールド */}
        <FormFields
          name={name}
          title={title}
          explanation={explanation}
          setName={setName}
          setTitle={setTitle}
          setExplanation={setExplanation}
          disabled={!isEditable}
        />
      </DialogContent>

      {/* アクションボタン */}
      <ActionButtons
        onSave={handleSave}
        onClose={onClose}
        onDelete={onDelete}
        onCheckIn={onCheckIn}
        isEditing={isEditable}
        isCreateUser={isCreateUser}
        isCheckedIn={isCheckedIn}
        isLogin={isLogin}
        isLoading={isLoading}
        hasLocationData={hasValidLocationData}
        generateGoogleMapLink={generateGoogleMapLink}
        hasChanges={hasChanges}
      />
    </Dialog>
  );
};

export default PhotoDropDialog;
