import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AUTH_PROVIDERS } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    minWidth: "80px",
    whiteSpace: "nowrap",
  },
  formBox: {
    margin: theme.spacing(2, 0),
  },
  formError: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  textCenter: {
    textAlign: "center",
  },
}));

/**
 * LoginButtonコンポーネントのProps
 */
interface LoginButtonProps {
  isLoggedIn: boolean;
  userDisplayName?: string;
  onLoginClick?: () => void;
  onProviderSelect?: (provider: string) => void;
  onEmailLogin?: (email: string, password: string, isSignUp: boolean) => void;
}

/**
 * ログイン/ログアウトボタンコンポーネント
 * ログイン状態に応じて表示を切り替え、複数の認証プロバイダーに対応
 */
const LoginButton: React.FC<LoginButtonProps> = ({
  isLoggedIn,
  userDisplayName,
  onLoginClick,
  onProviderSelect,
  onEmailLogin,
}) => {
  const classes = useStyles();

  // メニューの状態管理
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // メールログインのダイアログ表示状態
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  // メールログインのフォーム状態
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [formError, setFormError] = useState("");

  // ログインボタンのテキスト
  const loginText = process.env.REACT_APP_LOGIN_BUTTON_TEXT || "LOGIN";

  // ログアウト状態のテキスト
  const logoutText = userDisplayName
    ? `ログアウト (${userDisplayName})`
    : "ログアウト";

  // ボタンクリックハンドラ
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoggedIn) {
      // ログイン状態ならログアウト実行
      onLoginClick && onLoginClick();
    } else {
      // ログアウト状態ならメニュー表示
      setAnchorEl(event.currentTarget);
    }
  };

  // メニューを閉じる
  const handleClose = () => {
    setAnchorEl(null);
  };

  // 認証プロバイダー選択時の処理
  const handleProviderSelect = (provider: string) => {
    handleClose();
    onProviderSelect && onProviderSelect(provider);
  };

  // メールログインダイアログを開く
  const handleOpenEmailDialog = () => {
    handleClose();
    setEmailDialogOpen(true);
    resetForm();
  };

  // メールログインダイアログを閉じる
  const handleCloseEmailDialog = () => {
    setEmailDialogOpen(false);
    resetForm();
  };

  // フォームリセット
  const resetForm = () => {
    setEmail("");
    setPassword("");
    setFormError("");
  };

  // サインアップとログインの切り替え
  const toggleSignUpMode = () => {
    setIsSignUp(!isSignUp);
    setFormError("");
  };

  // メールログイン送信
  const handleEmailSubmit = () => {
    // バリデーション
    if (!email) {
      setFormError("メールアドレスを入力してください");
      return;
    }
    if (!password) {
      setFormError("パスワードを入力してください");
      return;
    }
    if (isSignUp && password.length < 6) {
      setFormError("パスワードは6文字以上で入力してください");
      return;
    }

    // コールバック実行
    onEmailLogin && onEmailLogin(email, password, isSignUp);
    handleCloseEmailDialog();
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
        className={classes.button}
      >
        {isLoggedIn ? logoutText : loginText}
      </Button>

      {/* 認証プロバイダー選択メニュー */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleProviderSelect(AUTH_PROVIDERS.TWITTER)}>
          Twitterでログイン
        </MenuItem>
        <MenuItem onClick={() => handleProviderSelect(AUTH_PROVIDERS.GOOGLE)}>
          Googleでログイン
        </MenuItem>
        <MenuItem onClick={handleOpenEmailDialog}>
          メールアドレスでログイン
        </MenuItem>
      </Menu>

      {/* メールログイン/サインアップダイアログ */}
      <Dialog open={emailDialogOpen} onClose={handleCloseEmailDialog}>
        <DialogTitle>
          {isSignUp ? "アカウント作成" : "メールアドレスでログイン"}
        </DialogTitle>
        <DialogContent>
          <Box className={classes.formBox}>
            <TextField
              label="メールアドレス"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              label="パスワード"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={
                isSignUp ? "6文字以上のパスワードを設定してください" : ""
              }
            />

            {formError && (
              <Typography variant="body2" className={classes.formError}>
                {formError}
              </Typography>
            )}
          </Box>

          <Divider className={classes.divider} />

          <Box className={classes.textCenter}>
            <Typography variant="body2">
              {isSignUp
                ? "すでにアカウントをお持ちの方はこちら"
                : "アカウントをお持ちでない方はこちら"}
            </Typography>
            <Button
              color="primary"
              onClick={toggleSignUpMode}
              style={{ marginTop: 8 }}
            >
              {isSignUp ? "ログイン画面へ" : "新規登録へ"}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailDialog}>キャンセル</Button>
          <Button
            onClick={handleEmailSubmit}
            variant="contained"
            color="primary"
          >
            {isSignUp ? "登録する" : "ログイン"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoginButton;
